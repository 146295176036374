import './styles/App.scss';
import Navigation from './layout/Navigation';
import { ThemeProvider } from "@mui/material/styles";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { Theme } from "./components/Theme";
import Dashboard from './view/dashboard';
import Withdrawals from './view/withdrawals';
import DailySummary from './view/dailysummary';
import HelpAndSupport from './view/support';
import Login from './view/login';
import Reports from './view/reports';
import Onboarding from './view/onboarding';
import Configure from './view/configure';
import TransactionBreakdown from './view/dailysummary/TransactionBreakdown';
import MarketplaceConnected from './components/MarketplaceConnected';
import EmailConfirmation from './components/EmailConfirmation';

function App() {
  return (
    <ThemeProvider theme={Theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/withdrawals" element={<Withdrawals />} />
          <Route path="/daily-summary" element={<DailySummary />} />
          <Route path="help-support" element={<HelpAndSupport />} />
          <Route path="reports" element={<Reports />} />
          <Route path="/configure" element={<Configure />} />
          <Route path="/transaction-breakdown" element={<TransactionBreakdown />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/onboard/consent" element={<MarketplaceConnected />} />
        <Route path="/onboard/verification" element={<EmailConfirmation />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
  );
}

export default App;
