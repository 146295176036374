import { BASE_URL } from "../utils/constants";
import { clearAllLocalStorage } from '../utils/localStorageManager';
import handler from "../utils/handler";
import { stringDate } from "../utils/utilityFunctions";

export const GetDashboardData = async(startDate, endDate) => {
    const accessToken = localStorage.getItem("accessToken");

    const datetostring = stringDate([startDate, endDate]);

    if(datetostring[0] === 'Invalid date'){
        datetostring[0] = datetostring[1]
    }

    if(datetostring[1] === 'Invalid date'){
        datetostring[1] = datetostring[0]
    }
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/dashboard/overviews/seller/marketplace-overview?startDate=${datetostring[0]}&endDate=${datetostring[1]}`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}

export const GetAccountBalances = async(value) => {
    const accessToken = localStorage.getItem("accessToken");
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/dashboard/overviews/seller/account-balances`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response);

        return result
       
    } catch (e) {
        throw e;
    }
}