import dateFormat from "dateformat";

export function groupByDateDailySummary(data) {
  if (data === undefined || data.length === 0) {
    return null;
  } else {
    var arrByDate = data.reduce((a, b) => {
      var i = a.findIndex(
        (x) =>
          dateFormat(x.requestdate, "mmm dS, yyyy") ===
          dateFormat(b.requestdate, "mmm dS, yyyy")
      );

      var x;
      var totalInvoices = 0;
      var totalAvailableBalance = 0;
      var totalReceivableBalance = 0;
      var totalMarketplaceBalance = 0;

      for (x = 0; x < b.marketplaceslist.length; x++) {
        totalInvoices += b.marketplaceslist[x].noofinvoices;
        totalAvailableBalance +=
          b.marketplaceslist[x].advertisingfeesavailablebalance +
          b.marketplaceslist[x].marketplacepaymentsavailablebalance +
          b.marketplaceslist[x].otherfeesavailablebalance +
          b.marketplaceslist[x].platformsubscriptionavailablebalance +
          b.marketplaceslist[x].totalnetsalesavailablebalance;
        totalReceivableBalance +=
          b.marketplaceslist[x].advertisingfeesreceivablebalance +
          b.marketplaceslist[x].marketplacepaymentsreceivablebalance +
          b.marketplaceslist[x].otherfeesreceivablebalance +
          b.marketplaceslist[x].platformsubscriptionreceivablebalance +
          b.marketplaceslist[x].totalnetsalesreceivablebalance;
        totalMarketplaceBalance +=
          b.marketplaceslist[x].marketplacepaymentsmarketplacebalance +
          b.marketplaceslist[x].totalnetsalesmarketplacebalance;
      }

      if (i === -1) {
        a.push({
          requestdate: b.requestdate,
          totalInvoices: totalInvoices,
          totalAvailableBalance,
          totalReceivableBalance,
          totalMarketplaceBalance,
          //   invoiceTotal: b.invoiceAmount,
          childInvoices: [{ ...b }],
        });
      } else {
        a[i].marketplaceslist.noofinvoices++;
        a[i].totalInvoices = totalInvoices;
        a[i].totalAvailableBalance = totalAvailableBalance;
        a[i].totalReceivableBalance = totalReceivableBalance;
        a[i].totalMarketplaceBalance = totalMarketplaceBalance;
        a[i].childInvoices.push({ ...b });
      }
      return a;
    }, []);

    return arrByDate;
  }
}

export function groupByDateWithdrawals(transfers) {
  const result = {};

  transfers.forEach(transfer => {
      // Extract the year and month from the transferRequestDate
      const date = new Date(transfer.transferRequestDate);
      const yearMonth = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;

      // Initialize the month if not already present
      if (!result[yearMonth]) {
          result[yearMonth] = {
              createdDate: yearMonth,
              transferAmountTotal: 0
          };
      }

      // Sum up the requestAmount for the month
      result[yearMonth].transferAmountTotal += transfer.requestAmount;
  });

  // Convert the result object into an array
  return Object.values(result);
};
