import React, { useEffect, useState } from "react";
import { Typography, Grid, Stack, Box, IconButton, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import amazonlogo from "../../assets/amazonlogo.png";
// import walmartlogo from "../../assets/wallmartlogo.png"
// import newegglogo from "../../assets/newegglogo.png"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { GreenOutlinedButton, LightBlueContainedButton, TextButton } from "../../components/ui/Buttons";
import { StyledTextBoxv2 } from "../../components/ui/TextBox";
import { GetMarketplaceRegions, GetMarketplace } from "../../services/markeplace.services";
import './style.css'

const boxStyle = {
	background: "#FFFFFF",
	p: 3,
	boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
	borderRadius: "10px",
};

const marketplaceBoxStyle = {
	...boxStyle,
	boxShadow: "4px 8px 6px rgba(0, 0, 0, 0.08)",
	cursor: 'pointer'
};

export default function LinkedMarketplaces() {
	const [open, setOpen] = useState(false);
	const [marketplaceRegions, setMarketplaceRegions] = useState(null);
	const [selectedRegion, setSelectedRegion] = useState('placeholder');
	const [enableMarketplaceSelect, setEnableMarketplaceSelect] = useState(true);
	const [platformId, setPlatformId] = useState(null)
	const [marketplaces, setMarketplaces] = useState(null);
	const [selectedMarketplaces, setSelectedMarketplaces] = useState('placeholder');
	const [selectedMarketplacesId, setSelectedMarketplacesId] = useState();
	const [disableButton, setDisableButton] = useState(true);
	const [authUrl, setAuthUrl] = useState(null);

	const uniqueId = localStorage.getItem("uniqueId")

	useEffect(() => {
		if (platformId === null) {
			handleClose();
		}
	}, [platformId])

	const handleClickOpen = (marketplaceId) => {
		setPlatformId(marketplaceId);
		if (marketplaceId) {
			GetMarketplaceRegions(marketplaceId).then((data) => {
				setMarketplaceRegions(data);
				setOpen(true);
			})
		}

	};

	const handleSetRegion = (event) => {
		setSelectedRegion(event.target.value);

		if (event.target.value && event.target.value !== 'placeholder') {
			setEnableMarketplaceSelect(false);
			GetMarketplace(platformId, event.target.value).then((data) => {
				setMarketplaces(data)
			})
		}

	}

	const handleSetMarketplace = (event) => {
		setSelectedMarketplaces(event.target.value);		
		const value = marketplaces.find(item => item.id === event.target.value);
		setAuthUrl(value.authEndpoint);

		if (value.authEndpoint) {
			setDisableButton(false);
		}
	}

	useEffect(() => {
        if (selectedMarketplaces) {
            setSelectedMarketplacesId(selectedMarketplaces);
        }
    }, [selectedMarketplaces]);

	const handleClose = () => {
		setOpen(false);
		setOpen(false);
		setSelectedRegion('placeholder');
		setSelectedMarketplaces('placeholder');
		setEnableMarketplaceSelect(true);
		setDisableButton(true);
	};

	return (
		<div>
			<Typography variant="h4">Lets get connected</Typography>
			<Box sx={boxStyle}>
				<Stack spacing={3}>
					<Typography variant="body1_light">
						Linking your platforms lets Vepay use your store metrics and health
						to determine your financing options instead of a credit check.
					</Typography>
					<Typography variant="body1_light">
						Add your platforms and marketplaces.
					</Typography>
				</Stack>
				<Grid container spacing={3}>
					<Grid item md={4} sm={6} xs={12}>
						{/* <a
			  href={`https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.e35ea2c4-9060-426e-967b-ccd62c756bbb&version=beta`}
			  target="_blank"
			  rel="noreferrer"
			> */}
						<Box sx={marketplaceBoxStyle} onClick={() => handleClickOpen(1)}>
							<Stack
								direction="row"
								spacing={8}
								alignItems="center"
								justifyContent="center"
							>
								<img src={amazonlogo} alt="Amazon" style={{ width: "50%" }} />
								<IconButton>
									<AddCircleOutlineIcon fontSize="large" />
								</IconButton>
							</Stack>
						</Box>
						{/* </a> */}
					</Grid>
					{/* <Grid item md={4} sm={6} xs={12}>
			<Box sx={marketplaceBoxStyle}>
					<Stack direction="row" spacing={8} alignItems="center" justifyContent="center">
						<img src={walmartlogo} alt="Wallmart" style={{width: '50%'}}/>
						<IconButton>
							<AddCircleOutlineIcon fontSize="large"/>
						</IconButton>
					</Stack>                    
				</Box>
			</Grid> */}
					{/* <Grid item md={4} sm={6} xs={12}>
			<Box sx={marketplaceBoxStyle}>
					<Stack direction="row" spacing={8} alignItems="center" justifyContent="center">
						<img src={newegglogo} alt="Newegg" style={{width: '50%'}}/>
						<IconButton>
							<AddCircleOutlineIcon fontSize="large"/>
						</IconButton>
					</Stack>                    
				</Box>
			</Grid> */}
				</Grid>
				<br></br>
				<Box sx={{ backgroundColor: "#dfdfdf", p: 3 }}>
					<Stack spacing={5} alignItems="flex-start">
						<Typography variant="body1_light">
							We'd like to assist you to complete the onboarding process. Book a
							time to speak with one of our customer support specialists.
						</Typography>
						<GreenOutlinedButton variant="outlined">
							Book A Time
						</GreenOutlinedButton>
					</Stack>
					<br></br>
				</Box>
			</Box>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Connect to your Amazon Marketplace
				</DialogTitle>
				<DialogContent>
					<br></br>
					<Stack>
						<Typography variant="body2" color="neutral.letter_Black">
							Regions
						</Typography>
						<StyledTextBoxv2
							fullWidth
							value={selectedRegion}
							select
							onChange={handleSetRegion}
						>
							<MenuItem disabled value="placeholder">
								<em>Select Region</em>
							</MenuItem>
							{marketplaceRegions?.map((data, index) => (
								<MenuItem value={data.id} key={index}>{data.name}</MenuItem>
							))}
						</StyledTextBoxv2>
					</Stack>
					<br></br>
					<Stack>
						<Typography variant="body2" color="neutral.letter_Black">
							Marketplace
						</Typography>
						<StyledTextBoxv2
							fullWidth
							value={selectedMarketplaces}
							select
							disabled={enableMarketplaceSelect}
							onChange={handleSetMarketplace}
						>
							<MenuItem disabled value="placeholder">
								<em>Select Marketplace</em>
							</MenuItem>
							{marketplaces?.map((data, index) => (
								<MenuItem value={data.id} key={index}>{data.name}</MenuItem>
							))}

						</StyledTextBoxv2>
					</Stack>
					<br></br>
				</DialogContent>
				<DialogActions>
					<TextButton variant="text" onClick={handleClose}>Close</TextButton>
					<LightBlueContainedButton disabled={disableButton} variant="contained" onClick={handleClose} autoFocus>
						<a
							href={`${authUrl}/apps/authorize/consent?application_id=amzn1.sp.solution.e35ea2c4-9060-426e-967b-ccd62c756bbb&version=beta&state=1-${uniqueId}-${selectedMarketplacesId}`}
							target="_blank"
							rel="noreferrer"
							className="text-link"
						>
							Proceed
						</a>
					</LightBlueContainedButton>
				</DialogActions>
			</Dialog>
		</div>
	);
}
