import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  background: "#FFFFFF",
  boxShadow: "0px 20px 80px rgba(13, 51, 115, 0.15)",
  borderRadius: "12px",
  "& .MuiCardContent-root": {
    padding: 0,
  },
  "& .MuiCardContent-root:last-child": {
    padding: 0,
  },
}));

export const StyledApplicationCard = styled(Card)(({ theme }) => ({
  background: "#FFFFFF",
  boxShadow: "0px 4px 8px rgba(13, 51, 115, 0.24)",
  borderRadius: "8px",
  width: "12.9975em",
  height: "6em",
}));

export const ActiveCard = styled(Card)(({ theme }) => ({
  background: "#FFFFFF",
  // boxShadow: '0px 4px 8px rgba(13, 51, 115, 0.24)',
  // borderRadius: '8px',
  width: "13.0625em",
  height: "11.274375em",
  border: "1px solid #C8D2D8",
  borderRadius: "6px",
  marginLeft: "1em",
  padding: "5%",
}));

export const Inactive = styled(Card)(({ theme }) => ({
  background: "#F6F6F6",
  // boxShadow: '0px 4px 8px rgba(13, 51, 115, 0.24)',
  // borderRadius: '8px',
  width: "13.0625em",
  height: "11.274375em",
  border: "1px solid #C8D2D8",
  marginLeft: "1em",
  padding: "5%",
}));

// The card element which is used in Unicorn Hub

export const ActionAreaCard = styled(Card)(({ theme }) => ({
  height: "220px",
  width: "350px",
  background: "#FFFFFF",
  boxShadow: "0px 4px 18px rgba(13, 51, 115, 0.16)",
  borderRadius: "8px",
}));
