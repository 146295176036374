import {
	Box,
	Grid,
	Stack,
	Typography,
	CardContent,
	InputAdornment,
	IconButton,
	Snackbar,
	CircularProgress,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import "./login.scss";
import leftImage from "../../assets/loginImage.svg";
import logoColor from "../../assets/logoColor.svg";
import { StyledTextBox } from "../../components/ui/TextBox";
import { StyledCard } from "../../components/ui/Cards";
import { ContainedButton } from "../../components/ui/Buttons";
import {
	OnboardingLogin,
	RequestAccessToken,
} from "../../services/auth.services";
import { NavLink, useNavigate } from "react-router-dom";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Checkbox from "@mui/material/Checkbox";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
// import { StyledCheckboxes } from "../../components/ui/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LoadingBox } from "../../components/ui/Box";
import AlertSnackbar from "../../components/shared/notifications/AlertSnackBar";

export default function Login() {
	const [values, setValues] = useState({
		username: "",
		password: "",
	});
	const [showPassword, setShowPassword] = useState(false);
	const [checkPassword, setCheckPassword] = useState(false);
	const [checkUserName, setCheckUserName] = useState(false);

	const [snackBarSuccess, setSnackBarSuccess] = useState(false);
	const [snackBarAlert, setSnackBarAlert] = useState(false);
	const [snackBarAlertMessage, setSnackBarAlertMessage] = useState('');
	const [amazonCallbackUri, setAmazonCallbackUri] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const currentUrl = window.location.href;
		const urlParams = new URLSearchParams(currentUrl.split("?")[1]);
		// Create an empty object to store parameters
		const params = {};

		// Loop through all parameters and add them to the object
		for (const [key, value] of urlParams.entries()) {
			params[key] = value;
		}

		// Log the parameters object
		setAmazonCallbackUri(params);
	}, []);

	useEffect(() => {
		if (amazonCallbackUri.spapi_oauth_code) {
			window.location.href = decodeURIComponent(
				`https://dev.vepay.io/onboard/consent?spapi_oauth_code=${amazonCallbackUri.spapi_oauth_code}&selling_partner_id=${amazonCallbackUri.selling_partner_id}&state=${amazonCallbackUri.state}`
			);
		} else {
			setIsLoading(false);
		}

	}, [amazonCallbackUri]);

	const matches = useMediaQuery("(min-width:900px)");

	const navigate = useNavigate();

	const handleSubmit = (event) => {
		event.preventDefault();
		let error = false;

		if (values.username === "") {
			setCheckUserName(true);
			error = true;
			setSnackBarAlert(true);
			setSnackBarAlertMessage('The email you entered is incorrect.');
		} else {
			setCheckUserName(false);
		}

		if (values.password === "") {
			setCheckPassword(true);
			error = true;
			setSnackBarAlert(true);
			setSnackBarAlertMessage('The password you entered is incorrect.');
		} else {
			setCheckPassword(false);
		}

		if (!error) {
			try {
				if (amazonCallbackUri.amazon_callback_uri) {
					OnboardingLogin(values).then((data) => {
						if (data.response.status === 200) {
							data.result.then((result) => {
								window.location.href = decodeURIComponent(
									`${amazonCallbackUri.amazon_callback_uri}?amazon_state=${amazonCallbackUri.amazon_state}&selling_partner_id=${amazonCallbackUri.selling_partner_id}&version=${amazonCallbackUri.version}&state=1-${result.uniqueId}&redirect_uri=https://dev.vepay.io/onboard/consent`
								);
							});
							setSnackBarSuccess(true);
						} else if (data.response.status === 404) {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('User not found.');
						} else if (data.response.status === 401) {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('The email or password you entered is incorrect.');
						} else {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('Somthing went wrong');
						}
					});
				} else {
					RequestAccessToken(values).then((data) => {
						if (data.status === 200) {
							//   setSnackBarSuccess(true);
							setTimeout(() => navigate("/dashboard"));
						} else if (data.status === 404) {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('User not found.');
						} else if (data.status === 401) {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('The email or password you entered is incorrect.');
						} else {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('Somthing went wrong');
						}
					});
				}
			} catch (error) {
				setSnackBarAlert(true);
				setSnackBarAlertMessage('Somthing went wrong');
				throw error;
			}
		}
	};
	return (
		<div className="main">
			{isLoading ? (
				<LoadingBox>
					<CircularProgress />
				</LoadingBox>
			) : (
				<Grid container alignItems="center" justifyContent="center">
					<StyledCard className="cardMain">
						<CardContent>
							<Grid container alignItems="center" justifyContent="center">
								<Grid item md={6} xs={12}>
									<Stack spacing={5} className="formContainer">
										<form onSubmit={(e) => handleSubmit(e)}>
											<Stack spacing={3}>
												<img src={logoColor} alt="logo" className="logo"/>
												<Typography variant="h1">Welcome Back</Typography>
												<Stack spacing={2}>
													<Stack>
														<Typography variant="body1_light">Email</Typography>
														<StyledTextBox
															required
															placeholder="Username or Email"
															error={checkUserName}
															value={values.username}
															id="username"
															name="username"
															autoComplete="username"
															onChange={(e) =>
																setValues({ ...values, username: e.target.value })
															}
															InputProps={{
																startAdornment: (
																	<InputAdornment position="start">
																		<Person2OutlinedIcon />
																	</InputAdornment>
																),
															}}
														/>
													</Stack>
													<Stack>
														<Typography variant="body1_light">Password</Typography>
														<StyledTextBox
															required
															placeholder="Password"
															error={checkPassword}
															type={showPassword ? "text" : "password"}
															value={values.password}
															id="password"
															name="password"
															autoComplete="current-password"
															onChange={(e) =>
																setValues({ ...values, password: e.target.value })
															}
															InputProps={{
																startAdornment: (
																	<InputAdornment position="start">
																		<LockOutlinedIcon />
																	</InputAdornment>
																),
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton
																			aria-label="toggle password visibility"
																			onClick={() => setShowPassword(!showPassword)}
																			edge="end"
																		>
																			{showPassword ? (
																				<Visibility />
																			) : (
																				<VisibilityOff />
																			)}
																		</IconButton>
																	</InputAdornment>
																),
															}}
														/>
													</Stack>
												</Stack>
												{/* <Stack
													direction="row"
													justifyContent="space-between"
													alignItems="center"
													sx={{ paddingTop: "2%" }}
													>
													<Stack direction="row" spacing={1}>
														<StyledCheckboxes
														control={<Checkbox label="Remember me" variant="body2" />}
														></StyledCheckboxes>
														<Typography variant="body2" textAlign="left">
														Remember me
														</Typography>
													</Stack>
													<Typography variant="body2" sx={{ color: "#093BA7" }}>
														Forget Password
													</Typography>
													</Stack> */}
												<Stack>
													<ContainedButton
														variant="contained"
														type="submit"
													>
														Sign In
													</ContainedButton>
												</Stack>
											</Stack>
										</form>
										<Stack>
											<Typography variant="body1_light">
												New to VePay?&nbsp;
												<NavLink
													to="/onboarding"
												>
													<span >Create Account</span>
												</NavLink>
											</Typography>
										</Stack>
									</Stack>
								</Grid>
								<Grid item md={6} sx={{ display: matches ? "flex" : "none" }}>
									<img
										src={leftImage}
										alt="Grow your business"
										style={{ width: "100%" }}
									/>
								</Grid>
							</Grid>
						</CardContent>
					</StyledCard>
				</Grid>
			)}
			<Snackbar
				open={snackBarSuccess}
				onClose={() => setSnackBarSuccess(false)}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				sx={{ top: "0 !important", left: "0 !important" }}
				autoHideDuration={6000}
			>
				<Box
					onClose={() => setSnackBarSuccess(false)}
					sx={{ width: "100vw", background: "#00AD47", p: 0.5 }}
				>
					<Stack
						direction="row"
						spacing={2}
						sx={{
							width: "100%",
							textAlign: "center",
							alignItems: "center",
							justifyContent: "center",
							color: "#FFFFFF",
						}}
					>
						<CheckCircleOutlineOutlinedIcon fontSize="small" />{" "}
						<Typography variant="body2">
							Authentification succsessful!
						</Typography>
					</Stack>
				</Box>
			</Snackbar>
			<AlertSnackbar
				open={snackBarAlert}
				message={snackBarAlertMessage}
				type="error"
				onClose={() => setSnackBarAlert(false)}
			/>			
		</div>
	);
}
