import { Snackbar, Box, Stack, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ICONS = {
	success: <CheckCircleOutlineIcon fontSize="small" />,
	error: <CancelOutlinedIcon fontSize="small" />,
	warning: <WarningAmberIcon fontSize="small" />,
	info: <InfoOutlinedIcon fontSize="small" />,
};

const COLORS = {
	success: "#28A745", // Green
	error: "#F33535", // Red
	warning: "#FFC107", // Yellow
	info: "#17A2B8", // Blue
};

const AlertSnackbar = ({ open, message, onClose, type = "error", duration = 6000 }) => {
	return (
		<Snackbar
			open={open}
			onClose={onClose}
			anchorOrigin={{ vertical: "top", horizontal: "left" }}
			sx={{ top: "0 !important", left: "0 !important" }}
			autoHideDuration={duration}
		>
			<Box onClick={onClose} sx={{ width: "100vw", background: COLORS[type], p: 0.5 }}>
				<Stack
					direction="row"
					spacing={2}
					sx={{
						width: "100%",
						textAlign: "center",
						alignItems: "center",
						justifyContent: "center",
						color: "#FFFFFF",
					}}
				>
					{ICONS[type]}
					<Typography variant="body2">{message}</Typography>
				</Stack>
			</Box>
		</Snackbar>
	);
};

export default AlertSnackbar;
