import React from "react";
import {
	Box,
} from "@mui/material";

export default function LookerStudio() {
	const accessToken = localStorage.getItem("userName");

	return (
		<div>
			  
			<Box
				sx={{
					p: 3,
					backgroundColor: "#FFFFFF",
					boxShadow: "0px 0px 4px rgba(3, 40, 120, 0.18)",
					borderRadius: "2px",
					height: '125vh', 
				}}
			>
				{accessToken === 'Hab Shifa-AUS'?  <iframe
                    width="100%"
                    height="100%"
                    src="https://lookerstudio.google.com/embed/reporting/7964bf3e-6158-479f-be9d-3c5c791d920f/page/cCtFE"
                    style={{ border: 0 }}
                    allowFullScreen
                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                    title="Looker Studio Report"
                /> : ''}

				{accessToken === 'Mohamed Azam Osman'?  <iframe
                    width="100%"
                    height="100%"
                    src="https://lookerstudio.google.com/embed/reporting/2506fff8-7629-45bf-aece-6cbe9848cacc/page/cCtFE"
                    style={{ border: 0 }}
                    allowFullScreen
                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                    title="Looker Studio Report"
                /> : ''}
				
			</Box>
			<br></br>
		</div>
	);
}
