import React from "react";
import { Typography, Stack, Box } from "@mui/material";
// import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
// import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

const boxStyle = {
  background: "#FFFFFF",
  p: 3,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
  borderRadius: "10px",
};

export default function Completed() {
  return (
    <div>
      <Box sx={boxStyle}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ color: "#093BA7" }}
          alignItems="center"
        >
          <Typography variant="h5">Done!</Typography>
        </Stack>
        <br></br>
        <Stack spacing={3}>
          <Typography variant="body1_light">
            Congratulations on successfully completing the onboarding process for VePay Inc.'s ethical and Halal factoring solutions. 
            We are pleased to confirm that we have received your application, and our team will now review it within 1 to 3 business days.
          </Typography>
          <Typography variant="body1_light">
            Should we require any additional information, we will reach out to you promptly. 
            We sincerely appreciate the trust you have placed in VePay, and we are excited to support your business growth.
          </Typography>
          <Typography variant="body1_light">
            Once your application is approved, you will recevice an email with
            access to your dedicated <b>VePay portal</b>. This portal will serve as a centralized platform for managing your factoring needs efficiently. 
            We are confident that our ethical financing solutions will empower your business and contribute to its success.
          </Typography>
          <Typography variant="body1_light">
            If you have any questions or need further assistance, please feel
            free to contact us at <b>support@vepay.io</b>. Thank you once again for choosing VePay as your trusted factoring partner.
            We look forward to a successful and long-term collaboration
          </Typography>
          <Stack spacing={1}>
          <Typography variant="body1_light">
            <b>Best regards,</b>
          </Typography>
          <Typography variant="body1_light">
            The VePay Team
          </Typography>
          </Stack>
        
        </Stack>

        <br></br>
      </Box>
    </div>
  );
}
