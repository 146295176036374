import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const LoadingBox = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "50vh",
}));

export const CardBox = styled(Box)(({ theme }) => ({
	padding: 24,
	backgroundColor: "#FFFFFF",
	height: "100%",
	boxShadow: "0px 0px 4px rgba(3, 40, 120, 0.18)",
	borderRadius: "2px",
}));
