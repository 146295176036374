// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tel-input .form-control {
    box-shadow: "none";
    border: none;
    border-radius: 4px !important;
    height: 2.5em !important;
    width: 100% !important;
    background: #F9F9FB;
}

.react-tel-input .country-list {
    text-align: left !important;
}

.react-tel-input .select-flag {
    background: #ffffff !important;
    border-radius: 8px 0px 0px 8px !important;
    border-right: 0px !important;
}

.react-tel-input .selected-flag {
    background: #ffffff !important;
    border-radius: 8px 0px 0px 8px !important;
    border-right: 0px !important;
    width: 0 !important;
}

.react-tel-input .flag-dropdown {
    background: #ffffff !important;
    border-radius: 8px 0px 0px 8px !important;
    border: 0px !important;
    top: 5px !important;
    bottom: 5px !important;
    left: 5px !important;

}

.react-tel-input .flag-dropdown.open .selected-flag {
    background: #F6F6F6 !important;
    border-radius: 8px 0px 0px 8px !important;
}

.text-link {
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/view/onboarding/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,wBAAwB;IACxB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,8BAA8B;IAC9B,yCAAyC;IACzC,4BAA4B;AAChC;;AAEA;IACI,8BAA8B;IAC9B,yCAAyC;IACzC,4BAA4B;IAC5B,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,yCAAyC;IACzC,sBAAsB;IACtB,mBAAmB;IACnB,sBAAsB;IACtB,oBAAoB;;AAExB;;AAEA;IACI,8BAA8B;IAC9B,yCAAyC;AAC7C;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".react-tel-input .form-control {\n    box-shadow: \"none\";\n    border: none;\n    border-radius: 4px !important;\n    height: 2.5em !important;\n    width: 100% !important;\n    background: #F9F9FB;\n}\n\n.react-tel-input .country-list {\n    text-align: left !important;\n}\n\n.react-tel-input .select-flag {\n    background: #ffffff !important;\n    border-radius: 8px 0px 0px 8px !important;\n    border-right: 0px !important;\n}\n\n.react-tel-input .selected-flag {\n    background: #ffffff !important;\n    border-radius: 8px 0px 0px 8px !important;\n    border-right: 0px !important;\n    width: 0 !important;\n}\n\n.react-tel-input .flag-dropdown {\n    background: #ffffff !important;\n    border-radius: 8px 0px 0px 8px !important;\n    border: 0px !important;\n    top: 5px !important;\n    bottom: 5px !important;\n    left: 5px !important;\n\n}\n\n.react-tel-input .flag-dropdown.open .selected-flag {\n    background: #F6F6F6 !important;\n    border-radius: 8px 0px 0px 8px !important;\n}\n\n.text-link {\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
