// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/LoginBackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.main{\n    min-height: 92vh;\n    display: flex;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    justify-content: center;\n    padding: 2% 5% 2% 5%;\n}\n\n.cardMain {\n    @media (max-width: 425px){\n        width: 100%;\n    }\n    @media (max-width: 768px){\n        width: 70%;\n    }\n\n    @media (max-width: 1024px){\n        width: 100%;\n    }\n    width: 70%;\n    max-width: 1500px;\n\n}", "",{"version":3,"sources":["webpack://./src/view/login/style.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,aAAa;IACb,yDAAyD;IACzD,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,UAAU;IACd;;IAEA;QACI,WAAW;IACf;IACA,UAAU;IACV,iBAAiB;;AAErB","sourcesContent":["\n.main{\n    min-height: 92vh;\n    display: flex;\n    background-image: url(\"../../assets/LoginBackground.png\");\n    justify-content: center;\n    padding: 2% 5% 2% 5%;\n}\n\n.cardMain {\n    @media (max-width: 425px){\n        width: 100%;\n    }\n    @media (max-width: 768px){\n        width: 70%;\n    }\n\n    @media (max-width: 1024px){\n        width: 100%;\n    }\n    width: 70%;\n    max-width: 1500px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
