// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.activeText{
    text-decoration: none;
    color: #FAFAFA;
    display: flex;
    flex-direction: row;
    background: #093BA7;
    padding: 3% 5% 3% 5%;
}

.activeText:hover {
    transform: scale(1.015);
    background: #093BA7;
    color: #FAFAFA;
    border-radius: 4px 4px 0px 0px;
}

.inActiveText{
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    padding: 3% 5% 3% 5%;
}

.inActiveText:hover {
    transform: scale(1.015);
    background: #093BA7;
    color: #FAFAFA;
    border-radius: 4px 4px 0px 0px;
}

`, "",{"version":3,"sources":["webpack://./src/layout/style.css"],"names":[],"mappings":";AACA;IACI,qBAAqB;IACrB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,8BAA8B;AAClC;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,8BAA8B;AAClC","sourcesContent":["\n.activeText{\n    text-decoration: none;\n    color: #FAFAFA;\n    display: flex;\n    flex-direction: row;\n    background: #093BA7;\n    padding: 3% 5% 3% 5%;\n}\n\n.activeText:hover {\n    transform: scale(1.015);\n    background: #093BA7;\n    color: #FAFAFA;\n    border-radius: 4px 4px 0px 0px;\n}\n\n.inActiveText{\n    text-decoration: none;\n    color: #FFFFFF;\n    display: flex;\n    flex-direction: row;\n    padding: 3% 5% 3% 5%;\n}\n\n.inActiveText:hover {\n    transform: scale(1.015);\n    background: #093BA7;\n    color: #FAFAFA;\n    border-radius: 4px 4px 0px 0px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
