import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import "@fontsource/open-sans";

export const ContainedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.primary.Blue_Dark,
  border: '1px solid #FFFFFF',
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.primary.Primary_Blue,
    boxShadow: ["none"],
  },
  borderRadius: '2px',
}));

export const GreenContainedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.success.Success_Dk,
  textTransform: "capitalize",
  fontWeight: "bold",
  // height: '377%',
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.success.Success_Dk,
    boxShadow: ["none"],
  },
  borderRadius: '2px',
}));

export const GreenOutlinedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  borderColor: theme.palette.success.Success_Dk,
  backgroundColor: theme.palette.neutral.Pure_White_Bg,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.success.Success_Dk,
  "&:hover": {
    backgroundColor: theme.palette.success.Success_Dk,
    color: theme.palette.neutral.Pure_White_Bg,
    boxShadow: ["none"],
  },
  borderRadius: '2px',
}));

export const BlackOutlinedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  borderColor: theme.palette.neutral.letter_Black,
  backgroundColor: theme.palette.neutral.Pure_White_Bg,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.letter_Black,
  "&:hover": {
    backgroundColor: theme.palette.neutral.Gray_Lite,
    color: theme.palette.neutral.Pure_White_Bg,
    boxShadow: ["none"],
  },
  borderRadius: '4px',
}));

export const LightBlueContainedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.primary.Blue_Bg_Lite,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.primary.Primary_Blue,
  "&:hover": {
    backgroundColor: theme.palette.primary.Blue_Bg_Dark,
    boxShadow: ["none"],
  },
  borderRadius: '2px',
}));

export const TextButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.primary.Pure_White_Bg,
  textTransform: "capitalize",
  fontFamily: "open Sans",
  fontWeight: 700,
  // fontSize: "14px", //14px
  // lineHeight: "1.125em",
  color: theme.palette.primary.Blue_Dark,
  "&:hover": {
    backgroundColor: theme.palette.primary.Blue_Bg_Lite,
    boxShadow: ["none"],
  },
  padding: "8px 12px",
}));


