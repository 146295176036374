import { BASE_URL } from "../utils/constants";
import { clearAllLocalStorage } from "../utils/localStorageManager";

const getAccessToken = () => localStorage.getItem("accessToken");

const createHeaders = (customHeaders = {}) => {
	const token = getAccessToken();
	return {
		"Content-Type": "application/json",
		...(token && { Authorization: `Bearer ${token}` }),
		...customHeaders,
	};
};

export const apiClient = async (endpoint, { method = "GET", body = null, headers = {} } = {}) => {
	try {
		const response = await fetch(`${BASE_URL}${endpoint}`, {
			method,
			headers: createHeaders(headers),
			body: body ? JSON.stringify(body) : null,
		});

		if (response.status === 401) {
			clearAllLocalStorage();
			return null; // Unauthorized case
		}

		if (!response.ok) {
			throw new Error(`API error: ${response.statusText}`);
		}

		return await response.json(); // Assuming all responses return JSON
	} catch (error) {
		console.error(`Error in API call to ${endpoint}:`, error);
		return null; // Handle gracefully
	}
};
