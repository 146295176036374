import React, { useState } from "react";
import { Typography, Grid, Stack, Box, IconButton } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { GreenContainedButton } from "../../components/ui/Buttons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PostDocumentUpload } from "../../services/auth.services";

const boxStyle = {
    background: "#FFFFFF",
    p: 3,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
    borderRadius: "10px",
};

export default function UploadandSign({ updateStep }) {
    const [files, setFiles] = useState([]);

    const handleFileChange = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            setFiles((prev) => {
                const newFiles = [...prev];
                newFiles[index] = file; // Store file in array at the correct index
                return newFiles;
            });
        }
    };

    const handleUpload = async () => {
        try {
            PostDocumentUpload(files).then((data) => {
                  if (data.ok) {
                    updateStep(5);
                  }
                });
            } catch (error) {
                throw error;
            }
    };

    const labels = [
        "National ID (Front)",
        "National ID (Back)",
        "Proof of Residential Address",
        "Bank Statement (Last 3 Months)",
        "Business Incorporation Document",
    ];

    return (
        <div>
            <Box sx={boxStyle}>
                <Stack direction="row" spacing={2} sx={{ color: "#093BA7" }} alignItems="center">
                    <CloudUploadOutlinedIcon />
                    <Typography variant="h5">Please upload the following documents</Typography>
                </Stack>

                <br />
                <Grid container spacing={3}>
                    {labels.map((label, index) => (
                        <Grid item xs={6} key={index}>
                            <Stack alignItems="center" spacing={1}>
                                <IconButton component="label">
                                    {files[index] ? (
                                        <img src={URL.createObjectURL(files[index])} alt={label} width={50} height={50} />
                                    ) : (
                                        <AttachFileOutlinedIcon fontSize="large" />
                                    )}
                                    <input type="file" hidden onChange={(e) => handleFileChange(e, index)} />
                                </IconButton>
                                <Typography variant="body2">{label}</Typography>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <br />
            <Stack justifyContent="flex-end" alignItems="flex-end">
                <GreenContainedButton variant="contained" onClick={handleUpload} endIcon={<ArrowForwardIosIcon />}>
                    Upload Documents
                </GreenContainedButton>
            </Stack>
        </div>
    );
}
