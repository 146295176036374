import { Typography, Grid, Stack, MenuItem, Box, Avatar } from "@mui/material";
import React from "react";
import { useState } from "react";
import { ContainedButton } from "../../components/ui/Buttons";
import { StyledTextBox } from "../../components/ui/TextBox";
import { useOutletContext } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "./style.css";
import PhoneInput from "react-phone-input-2";

const boxStyle = {
  background: "#FFFFFF",
  p: 3,
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04)",
  borderRadius: "2px",
};

export default function Personal() {
  const [userData] = useOutletContext();
  const [userInfo, setUserInfo] = useState({
    address: userData.address,
    country: userData.country,
    currency: userData.currency,
    email: userData.email,
    encryptedId: userData.encryptedId,
    firstName: userData.firstName,
    lastName: userData.lastName,
    timezone: userData.timezone,
    zipCode: userData.zipCode,
  });

  const [contactNo, setContactNo] = useState(userData.contactNo);

  const [errorCheckFirstName, setErrorCheckFirstName] = useState(false);
  const [errorCheckLastName, setErrorCheckLastName] = useState(false);
  const [errorCheckContactNo, setErrorCheckContactNo] = useState(false);

  const handleUpdate = (e) => {
    e.preventDefault();

    let error = false;

    if (userInfo.firstName === null || userInfo.firstName === "") {
      setErrorCheckFirstName(true);
      error = true;
    } else {
      setErrorCheckFirstName(false);
    }

    if (userInfo.lastName === null || userInfo.lastName === "") {
      setErrorCheckLastName(true);
      error = true;
    } else {
      setErrorCheckLastName(false);
    }

    if (userInfo.contactNo === null || userInfo.contactNo === "") {
      setErrorCheckContactNo(true);
      error = true;
    } else {
      setErrorCheckContactNo(false);
    }

    // console.log(error)

    if (!error) {
      // updateUserData(userInfo).then((data) => {
      //   //if(data.ok){navigate('/onboarding')}
      //   if (data.ok) {
      //     window.location = "/onboarding";
      //   }
      // });
    }
  };

  // console.log(userData)
  return (
    <div>
      <br></br>
      <Box sx={boxStyle}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h3" sx={{ textTransform: "uppercase" }}>
            Basic Information for Personal Profile
          </Typography>
        </Stack>

        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} alignItems="center">
            <Typography variant="h5" color="neutral.Gray_Dark">
              Profile Picture
            </Typography>
            <br></br>
            <Avatar
              alt="Dev"
              src="/static/images/avatar/1.jpg"
              sx={{ width: 80, height: 80 }}
            />
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                First Name
              </Typography>
              <StyledTextBox
                error={errorCheckFirstName ? true : false}
                fullWidth
                placeholder="First Name"
                value={userInfo.firstName}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, firstName: e.target.value })
                }
              />
              <Typography variant="body3" color="red">
                {errorCheckFirstName ? " First name required!" : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Last Name
              </Typography>
              <StyledTextBox
                error={errorCheckLastName ? true : false}
                fullWidth
                placeholder="Last Name"
                value={userInfo.lastName}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, lastName: e.target.value })
                }
              />
              <Typography variant="body3" color="red">
                {errorCheckLastName ? " Last name required!" : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Currency
              </Typography>
              <StyledTextBox fullWidth value="USD ($)" select>
                <MenuItem value="USD ($)">USD ($)</MenuItem>
              </StyledTextBox>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Timezone
              </Typography>
              <StyledTextBox
                fullWidth
                disabled
                value={userInfo.timezone}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, timezone: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h3" sx={{ textTransform: "uppercase" }}>
            Personal Contact Information
          </Typography>
        </Stack>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Contact Number
              </Typography>
              {/* <StyledTextBox
                fullWidth
                placeholder="+1 234 567 890"
                value={userInfo.contactNo}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, contactNo: e.target.value })
                }
              /> */}
              {errorCheckContactNo ? (
                <PhoneInput
                  country={"us"}
                  inputStyle={{
                    borderColor: "#d2302f",
                  }}
                  autoFormat={true}
                  value={contactNo}
                  onChange={setContactNo}
                />
              ) : (
                <PhoneInput
                  country={"us"}
                  autoFormat={true}
                  value={contactNo}
                  onChange={setContactNo}
                />
              )}

              <Typography variant="body3" color="red">
                {errorCheckContactNo ? "Enter a valid phone number" : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Email
              </Typography>
              <StyledTextBox
                fullWidth
                placeholder="example@gmail.com"
                disabled
                value={userInfo.email}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, email: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Address
              </Typography>
              <StyledTextBox
                fullWidth
                placeholder="No : Group 123 / A"
                value={userInfo.address}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, address: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                City
              </Typography>
              <StyledTextBox
                fullWidth
                placeholder="Phoenix"
                value={userInfo.city}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, city: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                State
              </Typography>
              <StyledTextBox
                fullWidth
                placeholder="Arizona"
                value={userInfo.state}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, state: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Zip code
              </Typography>
              <StyledTextBox
                fullWidth
                placeholder="10008"
                value={userInfo.zipCode}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, zipCode: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Country
              </Typography>
              <StyledTextBox fullWidth value="United States" select>
                <MenuItem value="United States">United States</MenuItem>
              </StyledTextBox>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <ContainedButton variant="contained" onClick={handleUpdate}>Update Now</ContainedButton>
      </Box>
      <br></br>
      <Box sx={boxStyle}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h3" sx={{ textTransform: "uppercase" }}>
            Change password
          </Typography>
        </Stack>

        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Typography variant="h5" color="neutral.Gray_Dark">
              Old Password
            </Typography>
            <StyledTextBox fullWidth placeholder="Enter old password" />
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Typography variant="h5" color="neutral.Gray_Dark">
              New Password
            </Typography>
            <StyledTextBox fullWidth placeholder="Enter new password" />
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Typography variant="h5" color="neutral.Gray_Dark">
              Confirm New Password
            </Typography>
            <StyledTextBox fullWidth placeholder="Re-enter new password" />
          </Grid>
        </Grid>
        <br></br>
        <ContainedButton variant="contained">Update Now</ContainedButton>
      </Box>
      <br></br>
    </div>
  );
}
