import {
  Typography,
  Grid,
  Stack,
  MenuItem,
  Box,
  Snackbar,
  Checkbox,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { StyledTextBoxv2 } from "../../components/ui/TextBox";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PhoneInput from "react-phone-input-2";
import { GreenContainedButton } from "../../components/ui/Buttons";
import { PostBusinessInfomation } from "../../services/auth.services";
import { geocodeByAddress } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { StyledDatePicker } from "../../components/ui/DatePicker";

const boxStyle = {
  background: "#FFFFFF",
  p: 3,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
  borderRadius: "10px",
};

export default function BusinessInfo({ updateStep }) {
  const [businessInfo, setbusinessInfo] = useState({
	name: {
	  value: "",
	  error: false,
	  message: "Business Name is required",
	},
	registrationNo: {
	  value: "",
	  error: false,
	  message: "Registration Number is required",
	},
	businessType: {
	  value: "",
	  error: false,
	  message: "Business Type is required",
	},
	industry: {
	  value: "FINANCIAL_SERVICES",
	  error: false,
	  message: "Segment is required",
	},
	connectedMarketPlaces: {
	  value: "AMAZON",
	  error: false,
	  message: "Connected Marketplaces is required",
	},
	contactNo: {
	  value: "",
	  error: false,
	  message: "Contact Number is required",
	},
	email: {
	  value: "",
	  error: false,
	  message: "Email is required",
	},
	address: {
	  value: "",
	  error: false,
	  message: "Address is required",
	},
	city: {
	  value: "",
	  error: false,
	  message: "City is required",
	},
	state: {
	  value: "",
	  error: false,
	  message: "State is required",
	},
	zipCode: {
	  value: "",
	  error: false,
	  message: "Zip Code is required",
	},
	country: {
	  value: "",
	  error: false,
	  message: "Country is required",
	},
	userUniqueId: {
	  value: localStorage.getItem("uniqueId") || "",
	  error: false,
	  message: "Contact Number is required",
	},
	storeLink: {
		value: "",
		error: false,
		message: "Store Front link is required",
	},
	o_fName: {
		value: "",
		error: false,
		message: "First Name is required",
	},
	o_lName: {
		value: "",
		error: false,
		message: "Last Name is required",
	},
	o_idPassportDl: {
		value: "",
		error: false,
		message: "National ID /Passport /Driving Licence  is required",
	},
	o_nationality: {
		value: "",
		error: false,
		message: "Nationality is required",
	},
	o_dateOfBirth: {
		value: moment().toDate(),
		error: false,
		message: "Date Of Birth is required",
	},
	o_ssn: {
		value: "",
		error: false,
		message: "Not Required",
	},
	o_contactNumber: {
		value: "",
		error: false,
		message: "Contact Number is required",
	},
	o_email: {
		value: "",
		error: false,
		message: "Email is required",
	},
	o_address: {
		value: "",
		error: false,
		message: "Address is required",
	},
	o_city: {
		value: "",
		error: false,
		message: "City is required",
	},
	o_state: {
		value: "",
		error: false,
		message: "State is required",
	},
	o_zipCode: {
		value: "",
		error: false,
		message: "Zip Code is required",
	},
	o_country: {
		value: "",
		error: false,
		message: "Country is required",
	},
  });
  const [logisticAndFulfilment, setLogisticAndFulfilment] = useState([]);
  const [logisticAndFulfilmentError, setLogisticAndFulfilmentError] = useState(false);
  const [inputAddress, setInputAddress] = useState(null);
  const [inputOwnerAddress, setInputOwnerAddress] = useState(null);
  const [snackBarAlert, setSnackBarAlert] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [businessTypeValue, setBusinessTypeValue] = useState("");
  const [usePersonalInfo, setUsePersonalInfo] = useState(false)
  const addressAPIKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const options = ["Delivery Service Provider", "Warehouse and Fulfilment Provider", "Fulfillment by merchant", "Multi-Channel Fulfillment"];

  useEffect(() => {
	if (inputAddress) {
	  geocodeByAddress(inputAddress.label).then((place) => {
		let updatedBusinessInfo = { ...businessInfo, address: { value: inputAddress.label} }; // Create a copy of userInfo
		
		for (var i = 0; i < place[0].address_components.length; i++) {
		  var addressType = place[0].address_components[i].types[0];

		  switch (addressType) {
			case 'locality':
			  updatedBusinessInfo.city.value = place[0].address_components[i].long_name;
			  break;
			case 'administrative_area_level_1':
			  updatedBusinessInfo.state.value = place[0].address_components[i].long_name;
			  break;
			case 'country':
			  updatedBusinessInfo.country.value = place[0].address_components[i].long_name;
			  break;
			case 'postal_code':
			  updatedBusinessInfo.zipCode.value = place[0].address_components[i].long_name;
			  break;
			default:
				break;
		  }
		}

		setbusinessInfo(updatedBusinessInfo); // Update state once after loop
	  });
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputAddress])

  useEffect(() => {
	if (inputOwnerAddress) {
	  geocodeByAddress(inputOwnerAddress.label).then((place) => {
		let updatedBusinessInfo = { ...businessInfo, o_address: { value: inputOwnerAddress.label} }; // Create a copy of userInfo
		
		for (var i = 0; i < place[0].address_components.length; i++) {
		  var addressType = place[0].address_components[i].types[0];

		  switch (addressType) {
			case 'locality':
			  updatedBusinessInfo.o_city.value = place[0].address_components[i].long_name;
			  break;
			case 'administrative_area_level_1':
			  updatedBusinessInfo.o_state.value = place[0].address_components[i].long_name;
			  break;
			case 'country':
			  updatedBusinessInfo.o_country.value = place[0].address_components[i].long_name;
			  break;
			case 'postal_code':
			  updatedBusinessInfo.o_zipCode.value = place[0].address_components[i].long_name;
			  break;
			default:
				break;
		  }
		}

		setbusinessInfo(updatedBusinessInfo); // Update state once after loop
	  });
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOwnerAddress])

  useEffect(() => {
	setInputAddress(null);
	if(usePersonalInfo) {
		const storedUser = JSON.parse(localStorage.getItem("personalInfo"));
		setbusinessInfo({...businessInfo,
				o_contactNumber: {
					value: storedUser.contactNo,
				},
				o_email: {
					value: storedUser.email,
				},
				o_address: {
					value: storedUser.address,
				},
				o_city: {
					value: storedUser.city,
				},
				o_state: {
					value: storedUser.state,
				},
				o_zipCode: {
					value: storedUser.zipCode,
				},
				o_country: {
					value: storedUser.country,
				},
				o_fName: {
					value: storedUser.firstName,
				},
				o_lName: {
					value: storedUser.lastName,
				},
			}
		)
	} else {
		setbusinessInfo({...businessInfo,
			o_contactNumber: {
				value: "",
			},
			o_email: {
				value: "",
			},
			o_address: {
				value: "",
			},
			o_city: {
				value: "",
			},
			o_state: {
				value: "",
			},
			o_zipCode: {
				value: "",
			},
			o_country: {
				value: "",
			},
			o_fName: {
				value: "",
			},
			o_lName: {
				value: "",
			},
		}
	)
	}
	// eslint-disable-next-line
  }, [usePersonalInfo]);

  const handleCheckboxChange = (value) => {
    setLogisticAndFulfilment((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value) // Remove if unchecked
        : [...prev, value] // Add if checked
    );
  };

  const handleSubmit = (e) => {
	e.preventDefault();

	let updatedbusinessInfo = { ...businessInfo, o_dateOfBirth:{...businessInfo.o_dateOfBirth, value: moment(businessInfo.o_dateOfBirth.value).format('L')},  businessType: { ...businessInfo.businessType, value: businessInfo.businessType.value === "OTHER" ? businessTypeValue: businessInfo.businessType.value } };
	
	let propertyValues = Object.fromEntries(
	  Object.entries(updatedbusinessInfo).map(([key, value]) => [
		key,
		value.value,
	  ])
	);

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	for (const key in updatedbusinessInfo) {
	  if (
		(!updatedbusinessInfo[key].value ||
		updatedbusinessInfo[key].value.trim() === "") && key !== "o_ssn"
	  ) {
		updatedbusinessInfo[key].error = true;
		setbusinessInfo({
		  ...businessInfo,
		  [key]: { ...businessInfo.error, error: true },
		});
		setSnackBarAlert(true);
		setSnackBarMessage(`Please fill Required fields`);
	  } else {
		updatedbusinessInfo[key].error = false;
		if (
		  key === "email" &&
		  !emailRegex.test(updatedbusinessInfo[key].value)
		) {
		  updatedbusinessInfo[key].error = true;
		}
	  }
	}

	const hasError = Object.values(updatedbusinessInfo).some(field => field.error === true);

	if(logisticAndFulfilment.length <= 0 ) {
		setLogisticAndFulfilmentError(true);
	} else {
		setLogisticAndFulfilmentError(false);
	}

	updatedbusinessInfo = { ...businessInfo, logisticAndFulfilment};

	propertyValues = {...propertyValues, logisticAndFulfilment};
	if (!hasError && !logisticAndFulfilmentError) {
	  try {
		PostBusinessInfomation(propertyValues).then((data) => {
		  if (data.status === 200) {
			updateStep(3);
		  } else if (data.status === 500) {
			setSnackBarAlert(true);
			setSnackBarMessage("Email already exists");
		  } else if (data.status === 400) {
			setSnackBarAlert(true);
			setSnackBarMessage("Please fill all fields");
		  } else {
			setSnackBarMessage("Somthing went wrong please try again");
		  }
		}).catch(error => {
			setSnackBarAlert(true);
			setSnackBarMessage(error.message);
		});
	  } catch (error) {
		setSnackBarAlert(true);
		setSnackBarMessage("Somthing went wrong please try again");
		throw error;
	  }
	}
  };
  return (
	<div>
	  <br></br>
	  <Box sx={boxStyle}>
		<Stack
		  direction="row"
		  spacing={2}
		  sx={{ color: "#093BA7" }}
		  alignItems="center"
		>
		  <FolderSharedIcon />
		  <Typography variant="h5">
			Basic Information for Business Profile
		  </Typography>
		</Stack>

		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Business Name
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Business Name"
				value={businessInfo.name.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					name: { ...businessInfo.name, value: event.target.value },
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.name.error ? businessInfo.name.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Segment
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				value={businessInfo.industry.value}
				select
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					industry: {
					  ...businessInfo.industry,
					  value: event.target.value,
					},
				  })
				}
			  >
				<MenuItem value="FINANCIAL_SERVICES">
				  Financial Services
				</MenuItem>
				<MenuItem value="E_COMMERCE">E-Commerce</MenuItem>
				<MenuItem value="EDUCATION">Education</MenuItem>
				<MenuItem value="HEALTH_AND_SERVICES">
				  Health & Services
				</MenuItem>
				<MenuItem value="OTHER">Other</MenuItem>
			  </StyledTextBoxv2>
			  <Typography variant="body3" color="red">
				{businessInfo.industry.error
				  ? businessInfo.industry.message
				  : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Registration Number
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Registration Number"
				value={businessInfo.registrationNo.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					registrationNo: { ...businessInfo.registrationNo, value: event.target.value },
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.registrationNo.error ? businessInfo.registrationNo.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Business Type
			  </Typography>
			   <StyledTextBoxv2
				fullWidth
				value={businessInfo.businessType.value}
				select
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					businessType: {
					  ...businessInfo.businessType,
					  value: event.target.value,
					},
				  })
				}
			  >
				<MenuItem value="LLC">LLC</MenuItem>
				<MenuItem value="C-Corp">C-Corp</MenuItem>
				<MenuItem value="S-Corp">S-Corp</MenuItem>
				<MenuItem value="OTHER">Other</MenuItem>
				</StyledTextBoxv2>
				{businessInfo.businessType.value === "OTHER" ? (
					<StyledTextBoxv2
						fullWidth
						placeholder="Business Type"
						value={businessTypeValue}
						onChange={(event) =>
							setBusinessTypeValue(event.target.value)
						}
					/>
				):(
					''
			  	)}
			  <Typography variant="body3" color="red">
				{businessInfo.businessType.error ? businessInfo.businessType.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Connected Market Places
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Connected Market Places"
				disabled
				value={businessInfo.connectedMarketPlaces.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					connectedMarketPlaces: {
					  ...businessInfo.connectedMarketPlaces,
					  value: event.target.value,
					},
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.connectedMarketPlaces.error
				  ? businessInfo.connectedMarketPlaces.message
				  : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Store Front Link
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Store Front Link"
				value={businessInfo.storeLink.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					storeLink: {
					  ...businessInfo.storeLink,
					  value: event.target.value,
					},
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.storeLink.error
				  ? businessInfo.storeLink.message
				  : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
				<Typography variant="body2" color="neutral.letter_Black">
					Logistic & Fulfillment Options
				</Typography>
				<FormGroup>
					{options.map((option, index) => (
						<FormControlLabel key={index} 
							control={
								<Checkbox 
									value={option}
									checked={logisticAndFulfilment.includes(option)}
									onChange={() => handleCheckboxChange(option)} 
									size="small"
								/>
							} 
							label={option}
						/>
					))}
				</FormGroup>
				<Typography variant="body3" color="red">
					{logisticAndFulfilmentError ? "Select at least one of the Logistic & Fulfillment Options" : ''}
				</Typography>
			</Grid>
		  </Grid>      
		</Grid>
	  </Box>
	  <br></br>
	  <Box sx={boxStyle}>
		<Stack
		  direction="row"
		  spacing={2}
		  sx={{ color: "#093BA7" }}
		  alignItems="center"
		>
		  	<LocalPhoneIcon />
		  	<Typography variant="h5">Business Contact Information</Typography>
		</Stack>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Contact Number
			  </Typography>
			  {businessInfo.contactNo.error ? (
				<PhoneInput
				  country={"us"}
				  inputStyle={{
					borderColor: "#d2302f",
				  }}
				  autoFormat={true}
				  value={businessInfo.contactNo.value}
				  onChange={(value) =>
					setbusinessInfo({
					  ...businessInfo,
					  contactNo: { ...businessInfo.contactNo, value },
					})
				  }
				/>
			  ) : (
				<PhoneInput
				  country={"us"}
				  autoFormat={true}
				  value={businessInfo.contactNo.value}
				  onChange={(value) =>
					setbusinessInfo({
					  ...businessInfo,
					  contactNo: { ...businessInfo.contactNo, value },
					})
				  }
				/>
			  )}

			  <Typography variant="body3" color="red">
				{businessInfo.contactNo.error
				  ? businessInfo.contactNo.message
				  : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Email
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="example@gmail.com"
				value={businessInfo.email.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					email: { ...businessInfo.email, value: event.target.value },
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.email.error ? businessInfo.email.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Business Address
			  </Typography>
			   {addressAPIKey && !usePersonalInfo ? (
			   <GooglePlacesAutocomplete
			   apiKey={addressAPIKey}
			   selectProps={{
				 value: inputAddress,
				 onChange: setInputAddress,
				 placeholder: "Search Places...",
				 styles: {
				   control: (provided) => ({
					 ...provided,
					 border: "none",
					 borderRadius: "4px",
					 height: "2.5em",
					 background: '#F9F9FB',
					 color: "#28282A",
				   }),
				 },
			   }}
			 />
			  ) : (
				<StyledTextBoxv2
				  fullWidth
				  value={businessInfo.address.value}
				  placeholder="Enter Address"
				  onChange={(event) =>
					setbusinessInfo({
					  ...businessInfo,
					  address: {
						...businessInfo.address,
						value: event.target.value,
					  },
					})
				  }
				/>
			  )}
			  <Typography variant="body3" color="red">
				{businessInfo.address.error ? businessInfo.address.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				City
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Phoenix"
				value={businessInfo.city.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					city: { ...businessInfo.city, value: event.target.value },
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.city.error ? businessInfo.city.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				State
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Arizona"
				value={businessInfo.state.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					state: { ...businessInfo.state, value: event.target.value },
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.state.error ? businessInfo.state.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Zip code
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="10008"
				value={businessInfo.zipCode.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					zipCode: {
					  ...businessInfo.zipCode,
					  value: event.target.value,
					},
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.zipCode.error ? businessInfo.zipCode.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Country
			  </Typography>
			  <StyledTextBoxv2 fullWidth placeholder="United State" value={businessInfo.country.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					country: {
					  ...businessInfo.country,
					  value: event.target.value,
					},
				  })
				} />
				 <Typography variant="body3" color="red">
				{businessInfo.country.error ? businessInfo.country.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
	  </Box>
	  <br></br>
	  <Box sx={boxStyle}>
		<Stack
		  direction="row"
		  spacing={2}
		  sx={{ color: "#093BA7" }}
		  alignItems="center"
		>
		  	<LocalPhoneIcon />
		  	<Typography variant="h5">Business Owner Contact Information - Should own more than 25% of the business</Typography>
			{localStorage.getItem("personalInfo") ? (<Stack direction="row" alignItems="center">
				<Checkbox
					checked={usePersonalInfo}
					onChange={(event) => setUsePersonalInfo(event.target.checked)}
				/>
				<Typography variant="body2" color="neutral.letter_Black">
					Use Personal information
				</Typography>
			</Stack>): ''}
			
		</Stack>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				First Name
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="First Name"
				value={businessInfo.o_fName.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					o_fName: { ...businessInfo.o_fName, value: event.target.value },
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.o_fName.error ? businessInfo.o_fName.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Last Name
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Last Name"
				value={businessInfo.o_lName.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					o_lName: {
					  ...businessInfo.o_lName,
					  value: event.target.value,
					},
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.o_lName.error ? businessInfo.o_lName.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
			  	National ID /Passport /Driving Licence ID
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="National ID /Passport /Driving Licence ID"
				value={businessInfo.o_idPassportDl.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					o_idPassportDl: { ...businessInfo.o_idPassportDl, value: event.target.value },
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.o_idPassportDl.error ? businessInfo.o_idPassportDl.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Nationality
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Nationality"
				value={businessInfo.o_nationality.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					o_nationality: {
					  ...businessInfo.o_nationality,
					  value: event.target.value,
					},
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.o_nationality.error ? businessInfo.o_nationality.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
				<Typography variant="body2" color="neutral.letter_Black">
					Date Of Birth
				</Typography>
			  	<LocalizationProvider dateAdapter={AdapterDateFns}>
					<StyledDatePicker
						openTo="day"
						views={["year", "month", "day"]}
						value={businessInfo.o_dateOfBirth.value}
						onChange={(newValue) => {
							setbusinessInfo({
								...businessInfo,
								o_dateOfBirth: { ...businessInfo.o_dateOfBirth, value: newValue },
							})
						}}
					/>
				</LocalizationProvider>
				<Typography variant="body3" color="red">
					{businessInfo.o_dateOfBirth.error ? businessInfo.o_dateOfBirth.message : ""}
				</Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Social Security Number (Only for US Citizans)
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Social Security Number"
				value={businessInfo.o_ssn.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					o_ssn: {
					  ...businessInfo.o_ssn,
					  value: event.target.value,
					},
				  })
				}
			  />
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Contact Number
			  </Typography>
			  {businessInfo.o_contactNumber.error ? (
				<PhoneInput
				  country={"us"}
				  inputStyle={{
					borderColor: "#d2302f",
				  }}
				  autoFormat={true}
				  value={businessInfo.o_contactNumber.value}
				  onChange={(value) =>
					setbusinessInfo({
					  ...businessInfo,
					  o_contactNumber: { ...businessInfo.o_contactNumber, value },
					})
				  }
				/>
			  ) : (
				<PhoneInput
				  country={"us"}
				  autoFormat={true}
				  value={businessInfo.o_contactNumber.value}
				  onChange={(value) =>
					setbusinessInfo({
					  ...businessInfo,
					  o_contactNumber: { ...businessInfo.o_contactNumber, value },
					})
				  }
				/>
			  )}

			  <Typography variant="body3" color="red">
				{businessInfo.o_contactNumber.error
				  ? businessInfo.o_contactNumber.message
				  : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Email
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="example@gmail.com"
				value={businessInfo.o_email.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					o_email: { ...businessInfo.o_email, value: event.target.value },
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.o_email.error ? businessInfo.o_email.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
				<Typography variant="body2" color="neutral.letter_Black">
				Address
				</Typography>
				{addressAPIKey && !usePersonalInfo ? (
				<GooglePlacesAutocomplete
					apiKey={addressAPIKey}
					selectProps={{
						value: inputOwnerAddress,
						onChange: setInputOwnerAddress,
						placeholder: "Search Places...",
						styles: {
						control: (provided) => ({
							...provided,
							border: "none",
							borderRadius: "4px",
							height: "2.5em",
							background: '#F9F9FB',
							color: "#28282A",
						}),
						},
					}}
			 	/>
			  ) : (
				<StyledTextBoxv2
				  fullWidth
				  value={businessInfo.o_address.value}
				  placeholder="Enter Address"
				  onChange={(event) =>
					setbusinessInfo({
					  ...businessInfo,
					  o_address: {
						...businessInfo.o_address,
						value: event.target.value,
					  },
					})
				  }
				/>
			  )}
			  <Typography variant="body3" color="red">
				{businessInfo.o_address.error ? businessInfo.o_address.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				City
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Phoenix"
				value={businessInfo.o_city.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					o_city: { ...businessInfo.o_city, value: event.target.value },
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.o_city.error ? businessInfo.o_city.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				State
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="Arizona"
				value={businessInfo.o_state.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					o_state: { ...businessInfo.o_state, value: event.target.value },
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.o_state.error ? businessInfo.o_state.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		  <Grid item xs={1} />
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Zip code
			  </Typography>
			  <StyledTextBoxv2
				fullWidth
				placeholder="10008"
				value={businessInfo.o_zipCode.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					o_zipCode: {
					  ...businessInfo.o_zipCode,
					  value: event.target.value,
					},
				  })
				}
			  />
			  <Typography variant="body3" color="red">
				{businessInfo.o_zipCode.error ? businessInfo.o_zipCode.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
		<br></br>
		<Grid container justifyContent="flex-start">
		  <Grid item xs={5} container direction="row" alignItems="center">
			<Grid item xs={12}>
			  <Typography variant="body2" color="neutral.letter_Black">
				Country
			  </Typography>
			  <StyledTextBoxv2 fullWidth placeholder="United State" value={businessInfo.o_country.value}
				onChange={(event) =>
				  setbusinessInfo({
					...businessInfo,
					o_country: {
					  ...businessInfo.o_country,
					  value: event.target.value,
					},
				  })
				} />
				 <Typography variant="body3" color="red">
				{businessInfo.o_country.error ? businessInfo.o_country.message : ""}
			  </Typography>
			</Grid>
		  </Grid>
		</Grid>
	  </Box>
	  <br></br>
	  <Stack justifyContent="flex-end" alignItems="flex-end">
		<GreenContainedButton
		  variant="contained"
		  onClick={(e) => handleSubmit(e)}
		  endIcon={<ArrowForwardIosIcon />}
		>
		  Next
		</GreenContainedButton>
	  </Stack>
	  <br></br>
	  <Snackbar
		open={snackBarAlert}
		onClose={() => setSnackBarAlert(false)}
		anchorOrigin={{ vertical: "top", horizontal: "left" }}
		sx={{ top: "0 !important", left: "0 !important", zIndex: 1 }}
		autoHideDuration={6000}
	  >
		<Box
		  onClose={() => setSnackBarAlert(false)}
		  sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
		>
		  <Stack
			direction="row"
			spacing={2}
			sx={{
			  width: "100%",
			  textAlign: "center",
			  alignItems: "center",
			  justifyContent: "center",
			  color: "#FFFFFF",
			}}
		  >
			<CancelOutlinedIcon fontSize="small" />
			<Typography variant="body2">{snackBarMessage}</Typography>
		  </Stack>
		</Box>
	  </Snackbar>
	  <br></br>
	</div>
  );
}
