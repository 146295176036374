import React from "react";
import {
  Typography,
  Stack,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  Dialog,
  Modal,
  Snackbar
} from "@mui/material";
import { useState, useEffect, useRef } from "react";

import { StyledTableCell, StyledTableContainer } from "../../components/ui/Table";
import { TextButton } from "../../components/ui/Buttons";
import { GreenBox, RedBox, YellowBox, BlueBox } from "../../components/ui/Chip";
import {
  GetSupportHistory,
  GetSingleSupportHistory,
  CancelRequest,
} from "../../services/support.services";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingBox } from "../../components/ui/Box";
// import moment from "moment";
import { ContainedButton } from "../../components/ui/Buttons";
import cancelRequestImg from "../../assets/cancelRequest.svg";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export const modelStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 4,
  paddingLeft: "5%",
  paddingRight: "5%",
  boxShadow: "0px 5px 25px 2px rgba(49, 49, 49, 0.25)",
};

export default function TicketHistory() {
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [supportHistory, setSupportHistory] = useState(null);
  const [supportSingleHistory, setSupportSingleHistory] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [supportId, setSupportId] = useState("");
  const [cancelRequest, setCancelRequest] = useState({
    encryptedId: "",
    status: "",
  });

  const [snackBarSuccess, setSnackBarSuccess] = useState(false);
  const [snackBarAlert, setSnackBarAlert] = useState(false);
	const hasRun = useRef(false);


  const handleClose = () => {
    setSupportId("");
    setCancelRequest({
      encryptedId: "",
      status: "",
    })
    setOpen(false);
  };

  const handleCancelModel = () => {
    handleClose();
    setCancelRequest({
      encryptedId: "",
      status: "",
    })
    setOpenCancel(false);
  };

  useEffect(() => {
    if (hasRun.current) return;
		hasRun.current = true;

    const fetchData = async () => {
      setIsLoading(false);
      try {
        await GetSupportHistory().then((data) => {
          setIsLoading(true);
          setSupportHistory(data);
        });
        await GetSingleSupportHistory(supportId).then((data) => {
          setIsLoading(true);
          setSupportSingleHistory(data);
        });
        setIsLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchData();
  }, [supportId]);

  if (!supportSingleHistory || !supportHistory)
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpen = (value) => {
    setSupportId(value);
    setCancelRequest({
      encryptedId: value,
      status: "CANCELLED"
    });
    setOpen(true);
  };

  const handleOpenCancelModel = () => {
    setOpenCancel(true);
  };

  const handleCancelRequest = () => {
    try {
      CancelRequest(cancelRequest).then((data) => {
        if (data.ok) {
          setSnackBarSuccess(true)
        } else {
          setSnackBarAlert(true);
        }
        handleCancelModel();
      });
    } catch (error) {
      setSnackBarAlert(true);
      throw error;
    }
  }

  return (
    <div>
      <Box
        sx={{
          p: 3,
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 0px 4px rgba(3, 40, 120, 0.18)",
          borderRadius: "2px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* <Stack direction="row" spacing={2}>
            <StyledTextBox variant="outlined" placeholder="Search" />
            <LightBlueContainedButton variant="contained">
              Since joining
            </LightBlueContainedButton>
          </Stack> */}
          <Typography variant="body1">Showing results 1-15 of 70</Typography>
        </Stack>
        <br></br>
        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <StyledTableCell align="left">
                  <Typography variant="h5">Date</Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="h5">Reference No.</Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="h5">Issue Title</Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="h5">Issue Category</Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="h5">Status</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="h5">Action</Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {isLoading ? (
                <LoadingBox>
                  <CircularProgress />
                </LoadingBox>
              ) : (
                ""
              )} */}
              {supportHistory
                .sort(
                  (a, b) =>
                    parseFloat(b.referenceNo) - parseFloat(a.referenceNo)
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <>
                    <TableRow key={index}>
                      <TableCell align="left">
                        <Typography variant="body1">Jan 18, 2023</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {data.referenceNo}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {data.ticketSubject}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {data.issueType}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {(() => {
                          if (data.status === "IN_PROGRESS") {
                            return (
                              <BlueBox>
                                <b>In-Progress</b>
                              </BlueBox>
                            );
                          } else if (data.status === "PENDING") {
                            return (
                              <YellowBox>
                                <b>Pending</b>
                              </YellowBox>
                            );
                          } else if (data.status === "CANCELLED") {
                            return (
                              <RedBox>
                                <b>Closed</b>
                              </RedBox>
                            );
                          } else if (data.status === "COMPLETED") {
                            return (
                              <GreenBox>
                                <b>Completed</b>
                              </GreenBox>
                            );
                          }
                        })()}
                      </TableCell>
                      <TableCell align="center">
                        <TextButton
                          variant="text"
                          onClick={() => handleOpen(data.encryptedId)}
                        >
                          View
                        </TextButton>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Dialog
          open={open}
          fullWidth
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 6 }}>
            {isLoading ? (
              <LoadingBox>
                <CircularProgress />
              </LoadingBox>
            ) : (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h4" textAlign="center">
                      Reference No:
                    </Typography>
                    <Typography variant="h5_light" textAlign="center">
                      {supportSingleHistory.referenceNo}
                    </Typography>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h4" textAlign="center">
                      Status:
                    </Typography>
                    {(() => {
                      if (supportSingleHistory.status === "IN_PROGRESS") {
                        return (
                          <BlueBox>
                            <b>In-Progress</b>
                          </BlueBox>
                        );
                      } else if (supportSingleHistory.status === "PENDING") {
                        return (
                          <YellowBox>
                            <b>Pending</b>
                          </YellowBox>
                        );
                      } else if (supportSingleHistory.status === "CANCELLED") {
                        return (
                          <RedBox>
                            <b>Closed</b>
                          </RedBox>
                        );
                      } else if (supportSingleHistory.status === "COMPLETED") {
                        return (
                          <GreenBox>
                            <b>Completed</b>
                          </GreenBox>
                        );
                      }
                    })()}
                  </Stack>
                </Stack>

                <br></br>
                <Stack>
                  <Typography variant="h5">Issue Title</Typography>
                  <Typography variant="h5_light">
                    {supportSingleHistory.ticketSubject}
                  </Typography>
                </Stack>
                <br></br>
                <Stack>
                  <Typography variant="h5">Issue Category</Typography>
                  <Typography variant="h5_light">
                    {supportSingleHistory.issueType}
                  </Typography>
                </Stack>
                <br></br>
                <Stack>
                  <Typography variant="h5">Issue Description</Typography>
                  <Typography variant="h5_light">
                    {supportSingleHistory.ticketDescription}
                  </Typography>
                </Stack>

                <br></br>
                <br></br>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <ContainedButton
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={handleClose}
                  >
                    Done
                  </ContainedButton>
                  <TextButton
                    variant="text"
                    size="large"
                    fullWidth
                    onClick={() =>
                      handleOpenCancelModel()
                    }
                  >
                    Cancel Request
                  </TextButton>
                </Stack>
              </>
            )}
          </Box>
        </Dialog>

        {/* cancel support Modal Start */}
        <Modal
          open={openCancel}
          onClose={handleCancelModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modelStyle}>
            <br></br>
            <Stack alignItems="center">
              <img src={cancelRequestImg} alt="Logo" style={{ width: "30%" }} />
            </Stack>
            <br></br>
            <Stack sx={{ marginBottom: "10%" }} spacing={2}>
              <Typography
                id="modal-modal-title"
                variant="h2"
                textAlign="center"
              >
                <b>Cancel Request</b>
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                textAlign="center"
              >
                Support request will be canceled and marked as closed. You will
                not get contacted by our support team.
              </Typography>
            </Stack>
            <Stack alignItems="center" spacing={2}>
              <ContainedButton
                variant="contained"
                size="large"
                sx={{ width: "50%" }}
                onClick={() => {
                  handleCancelRequest();
                }}
              >
                Done
              </ContainedButton>
              <TextButton
                variant="text"
                size="large"
                fullWidth
                onClick={() => {
                  handleCancelModel();
                }}
              >
                Cancel
              </TextButton>
            </Stack>
            <br></br>
          </Box>
        </Modal>
        {/* cancel support Modal End */}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={supportHistory.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Snackbar
        open={snackBarSuccess}
        onClose={() => setSnackBarSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ top: "0 !important", left: "0 !important" }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setSnackBarSuccess(false)}
          sx={{ width: "100vw", background: "#00AD47", p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            <CheckCircleOutlineOutlinedIcon fontSize="small" />{" "}
            <Typography variant="body2">
              Cancel request succsessful!
            </Typography>
          </Stack>
        </Box>
      </Snackbar>

      <Snackbar
        open={snackBarAlert}
        onClose={() => setSnackBarAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ top: "0 !important", left: "0 !important" }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setSnackBarAlert(false)}
          sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            <CancelOutlinedIcon fontSize="small" />{" "}
            <Typography variant="body2">
              Something went wrong please try again.
            </Typography>
          </Stack>
        </Box>
      </Snackbar>
    </div>
  );
}
