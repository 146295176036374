// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linkText {
    color: #000;
    transition: transform .2s;
    -webkit-text-decoration: 1px underline dashed #575BB6;
            text-decoration: 1px underline dashed #575BB6;
    text-underline-offset: 4px;
}`, "",{"version":3,"sources":["webpack://./src/assets/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,qDAA6C;YAA7C,6CAA6C;IAC7C,0BAA0B;AAC9B","sourcesContent":[".linkText {\n    color: #000;\n    transition: transform .2s;\n    text-decoration: 1px underline dashed #575BB6;\n    text-underline-offset: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
