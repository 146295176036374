import { BASE_URL } from "../utils/constants";
import handler from "../utils/handler";
import { clearAllLocalStorage } from '../utils/localStorageManager';
import { stringDate } from "../utils/utilityFunctions";

export const GetMarketplaceReport = async(value) => {
    const accessToken = localStorage.getItem("accessToken");
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/reports/seller/marketplace-payment`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}

export const GetTransactionReport = async(value) => {
    const accessToken = localStorage.getItem("accessToken");
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/reports/seller/transactions`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}

export const GetVepayFeeReport = async(value) => {
    const accessToken = localStorage.getItem("accessToken");
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/reports/seller/vepay-fees`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}
export const GetTransactionBreakdown = async(transactionType, startDate, endDate) => {
    const accessToken = localStorage.getItem("accessToken");
    const datetostring = stringDate([startDate, endDate]);
   
    

    if(datetostring[0] === 'Invalid date'){
        datetostring[0] = datetostring[1]
    }

    if(datetostring[1] === 'Invalid date'){
        datetostring[1] = datetostring[0]
    } 

    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }
        let response = await fetch(`${BASE_URL}/core/daily-summary-drill?start=${datetostring[0]}&end=${datetostring[1]}&type=${transactionType}`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}

export const GetGeneratedReport = async(value) => {

    const accessToken = localStorage.getItem("accessToken");
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/llm/custom-report`, {
            method: "POST",
            headers,
            body: JSON.stringify(value)
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };


        let result = await response.json();

        return {response, result};
    
    } catch (e) {
        throw e;
    }
}