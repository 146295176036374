import React from "react";
import { useState, useEffect } from "react";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Highcharts from "highcharts";
import CircleIcon from "@mui/icons-material/Circle";
import HighchartsReact from "highcharts-react-official";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Typography,
	Stack,
	IconButton,
	Divider,
	Grid,
	Avatar,
	Collapse,
	Badge,
	Tooltip,
	TextField,
	Popover,
	Chip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import { TextButton } from "../../components/ui/Buttons";
import { formatCurrency, removeNegative } from "../../utils/utilityFunctions";
import useMediaQuery from "@mui/material/useMediaQuery";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import solidGauge from "highcharts/modules/solid-gauge";
import hcMore from 'highcharts/highcharts-more';
import amazonLogo from '../../assets/amazonlogosmall.svg'
import { CardBox } from "../../components/ui/Box";
import moment from "moment";
import { LightBlueContainedButton, TextButton } from "../../components/ui/Buttons";
import { FilterAlt } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseIcon from "@mui/icons-material/Close";

hcMore(Highcharts);
solidGauge(Highcharts);

export default function TransactionDetails(props) {
	const matches = useMediaQuery("(min-width:768px)");
	const smallScreen = useMediaQuery("(min-width:1280px)");
	const [data, setData] = useState(props.data);
	const [filter, setFilter] = useState(null); //state of the filter
	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState(false);
	const [open] = useState(true);

	useEffect(() => {

		setData(props.data);
		// eslint-disable-next-line
	}, [props]);

	if (!data) return null;

	const chartOptions = {
		title: {
			text: "",
		},
		chart: {
			type: "pie",
			height: smallScreen ? 180 : 150,
			width: smallScreen ? 180 : 150,
		},
		yAxis: {
			title: false
		},
		xAxis: {
			lineWidth: 0
		},
		colors: ["#35BD7D", "#FFB701"],
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},
		},
		credits: {
			enabled: false,
		}, // The water mark removal place
	};

	const handleFilter = () => {
		props.filterValue(props.startDate, props.endDate);
		handleCloseMP();
	};

	const handleQuickFilter = (startDate) => {
		props.setStartDate(startDate);
		props.setEndDate(new Date());
		props.filterValue(startDate, new Date());
		handleCloseMP();
	}

	const handlerFilterReset = () => {
		props.setStartDate(moment(localStorage.getItem("factorStartDate")).toDate());
		props.setEndDate(new Date());
		props.filterValue(props.setStartDate, props.setEndDate(new Date()));
		handleCloseMP();
	};

	const handleClickMP = (event) => {
		setFilter(event.currentTarget);
	};

	const handleCloseMP = () => {
		setFilter(null);
	};

	const openMP = Boolean(filter);
	const idMP = openMP ? "simple-popover" : undefined;

	return (
		<CardBox>
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
				sx={{ pb: 1 }}
			>
				<Grid item xs={12}>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Stack direction="row" alignItems="center" spacing={2}>
							<IconButton>
								<Avatar alt={data.marketplaceName} src={amazonLogo} />
							</IconButton>
							<Typography variant="h4">{data.marketplaceName}</Typography>
						</Stack>
						<Stack spacing={1}>
							<Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
								<Stack direction="row" alignItems="center" spacing={2}>
									<Typography variant="body1">
										Time period <b>{moment(props.startDate).format("MMM D, y")} -&nbsp;
										{moment(props.endDate).format("MMM D, y")}</b>
									</Typography>
								</Stack>
								<TextButton variant="text" onClick={handleClickMP}>
									<FilterAlt />
								</TextButton>
							</Stack>
							
						</Stack>
					</Stack>
					<Popover
						id={idMP}
						open={openMP}
						anchorEl={filter}
						onClose={handleCloseMP}
						PaperProps={{
							style: {
								padding: "1% 2% 2% 2%",
								width: "25%",
								borderRadius: "10px",
							},
						}}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
					>
						<Stack
							direction="row"
							justifyContent="space-between"
							spacing={2}
							alignItems="center"
						>
							<Typography sx={{ p: 2 }} variant="h5">
								<b>Filter Report</b>
							</Typography>
							<CloseIcon onClick={handleCloseMP} />
						</Stack>
						<br></br>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<div>
								<Stack sx={{ width: "100%" }} spacing={2}>
									<DatePicker
										openTo="day"
										views={["year", "month", "day"]}
										label="Start"
										value={props.startDate}
										maxDate={props.endDate}
										onChange={(newValue) => {
											props.setStartDate(newValue);
										}}
										renderInput={(params) => (
											<>
												{setStartDateError(params.error)}
												<TextField {...params} helperText={null} />
											</>
										)}
									/>
									<DatePicker
										openTo="day"
										views={["year", "month", "day"]}
										label="End"
										value={props.endDate}
										minDate={props.startDate}
										onChange={(newValue) => {
											props.setEndDate(newValue);
										}}
										renderInput={(params) => (
											<>
												{setEndDateError(params.error)}
												<TextField {...params} helperText={null} />
											</>
										)}
									/>
								</Stack>
							</div>
						</LocalizationProvider>
						<br></br>
						<Stack direction="row" spacing={2} justifyContent="center">
							<Chip label="1M" size="small" variant="outlined" onClick={() => handleQuickFilter(moment().subtract(30, 'days').toDate())} />
							<Chip label="6M" size="small" variant="outlined" onClick={() => handleQuickFilter(moment().subtract(90, 'days').toDate())} />
							<Chip label="YTD" size="small" variant="outlined" onClick={() => handleQuickFilter(moment().startOf('year').toDate())} />
							<Chip label="1Y" size="small" variant="outlined" onClick={() => handleQuickFilter(moment().subtract(365, 'days').toDate())} />
						</Stack>
						<br></br>
						<Stack direction="row" spacing={2}>
							{startDateError === false && endDateError === false ? (
								<LightBlueContainedButton
									fullWidth
									variant="outlined"
									onClick={handleFilter}
									size="large"
								>
									Filter
								</LightBlueContainedButton>
							) : (
								<LightBlueContainedButton fullWidth variant="outlined" disabled>
									Apply
								</LightBlueContainedButton>
							)}
							<LightBlueContainedButton
								fullWidth
								variant="contained"
								onClick={handlerFilterReset}
								size="large"
							>
								Reset
							</LightBlueContainedButton>
						</Stack>
					</Popover>
				</Grid>

				<Grid item xs={6}>
					<Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
						{/* <TextButton variant="text">
			  <CalendarMonthIcon
				sx={{ display: { xs: "block", sm: "none" } }}
			  />
			  <Typography
				variant="h5"
				sx={{
				  display: { xs: "none", sm: "block" },
				}}
			  >
				This month
			  </Typography>
			  <ExpandMoreIcon sx={{ display: { xs: "none", sm: "block" } }} />
			</TextButton> */}
						{/* <TextButton variant="text">
			  <FileDownloadOutlinedIcon />
			  <Typography
				sx={{
				  display: { xs: "none", sm: "block" },
				}}
			  >
				&nbsp;Export statement
			  </Typography>
			</TextButton> */}
					</Stack>
				</Grid>
			</Grid>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<Divider />
				<Grid
					container
					rowSpacing={3}
					alignItems="flex-start"
					justifyContent="space-between"
				>
					<Grid item sm={8} sx={{ pl: matches ? 7 : 0, mt: 2 }}>
						<Grid container>
							<Grid item md={12}>
								<Stack direction="row" spacing={2} alignItems="center">
									<Typography variant="h5" sx={{ color: "#093BA7", pb: 1 }}>
										Marketplace Summary
									</Typography>
									{/* <IconButton>
					<ExpandMoreIcon sx={{ color: "#093BA7" }} />
				  </IconButton> */}
								</Stack>
							</Grid>
							<Grid container sx={{ pb: 3 }} spacing={2}>
								<Grid item xs={4}>
									<Stack>
										<Typography variant="h4_bold">
											{formatCurrency(data.fundedAmount, true)}
										</Typography>
										<Typography variant="h5" sx={{ color: "#868B98" }}>
											Available Balance &nbsp;
											<Badge
												badgeContent={
													<Tooltip
														disableFocusListener
														disableTouchListener
														title="70% of the Net Sales Amount, after deducting Marketplace Charges and Adjustments."
													>
														<InfoOutlinedIcon
															sx={{
																color: "#989898",
																marginLeft: "100%",
																fontSize: 16
															}}
														/>
													</Tooltip>
												}
											/>
										</Typography>
									</Stack>
								</Grid>
								<Grid item xs={4}>
									<Stack>
										<Typography variant="h4">
											{formatCurrency(data.receivableBalance, true)}
										</Typography>
										<Typography variant="h5" sx={{ color: "#868B98" }}>
											Receivable Balance &nbsp;
											<Badge
												badgeContent={
													<Tooltip
														disableFocusListener
														disableTouchListener
														title="Remaining Receivable Amount minus the VePay Fee and any Marketplace Adjustments"
													>
														<InfoOutlinedIcon
															sx={{
																color: "#989898",
																marginLeft: "100%",
																fontSize: 16
															}}
														/>
													</Tooltip>
												}
											/>
										</Typography>
									</Stack>
								</Grid>
								<Grid item xs={4}>
									<Stack>
										<Typography variant="h4">
											{formatCurrency(data.marketplaceBalance, true)}
										</Typography>
										<Typography variant="h5" sx={{ color: "#868B98" }}>
											Marketplace Balance &nbsp;
											<Badge
												badgeContent={
													<Tooltip
														disableFocusListener
														disableTouchListener
														title="The Marketplace Balance represents the total earnings from your sales after deducting refunds, platform fees and any marketplace disbursement. It shows the net amount receivable from your transactions."
													>
														<InfoOutlinedIcon
															sx={{
																color: "#989898",
																marginLeft: "100%",
																fontSize: 16
															}}
														/>
													</Tooltip>
												}
											/>
										</Typography>
									</Stack>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						sm={4}
						container
						alignItems="center"
						justifyContent="flex-start"
					>
						<Grid container item xs={8} sm={6} justifyContent="flex-end">
							<HighchartsReact
								options={{
									...chartOptions,
									series: [
										{
											name: "Total ($)",
											data: [
												{ name: "Sales", y: removeNegative(data.totalNetSales) },
												{ name: "Returns", y: removeNegative(data.totalNetReturns) },
											],
											innerSize: "50%",
											showInLegend: false,
											dataLabels: {
												enabled: false,
											},
										},
									],
								}}
								highcharts={Highcharts}
							/>
						</Grid>
						<Grid item container xs={4}>
							<Grid item md={12} sx={{ pb: 3 }}>
								<Stack direction="row" spacing={2}>
									<CircleIcon sx={{ color: "#35BD7D" }} fontSize="small" />
									<Stack>
										<Typography variant="body1">Total Net Sales</Typography>
										<Typography variant="h4">
											{formatCurrency(data.totalNetSales, true)}
										</Typography>
									</Stack>
								</Stack>
							</Grid>
							<Grid item md={12}>
								<Stack direction="row" spacing={2}>
									<CircleIcon sx={{ color: "#FFB701" }} fontSize="small" />
									<Stack>
										<Typography variant="body1">Total&nbsp;Net&nbsp;Returns</Typography>
										<Typography variant="h4">
											{formatCurrency(data.totalNetReturns, true)}
										</Typography>
									</Stack>
								</Stack>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Collapse>
		</CardBox>
	);
}
