import { Grid, Stack, Typography, CardContent, Snackbar, Box } from "@mui/material";
import React, { useState } from "react";
import { StyledCard } from "./ui/Cards";
import { ContainedButton } from "./ui/Buttons";
import { useNavigate } from "react-router-dom";
import ConnectedLogo from "../assets/connected.svg";
import { PlatformAuth } from "../services/auth.services";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export default function EmailConfirmation() {
  const [snackBarAlert, setSnackBarAlert] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get("userID");

  const navigate = useNavigate();

  const handleSubmit = () => {
    const value = {
      state,
    };
    try {
      PlatformAuth(value).then((data) => {
        if (data.ok) {
          setTimeout(() => navigate("/onboarding?markeplacelinked=true"), 2000);
        } else {
          setSnackBarAlert(true);
          setSnackBarMessage("Somthing went wrong please try again");
        }
      });
    } catch (error) {
      setSnackBarAlert(true);
      setSnackBarMessage("Somthing went wrong please try again");
      throw error;
    }
  };
  return (
    <div className="main">
      <Grid container alignItems="center" justifyContent="center">
        <StyledCard sx={{ width: "50%" }}>
          <CardContent>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item md={12} sx={{ padding: "10%" }} textAlign="center">
                <Typography variant="h1">Marketplace Connected</Typography>
                <br></br>
                <img src={ConnectedLogo} alt="logo" style={{ width: "50%" }} />
                <br></br>
                <br></br>
                <Stack>
                  <Typography variant="body1_light">
                    Your Amazon store has been connected
                    and data has been transferred. You are all set to get
                    factoring.
                  </Typography>
                </Stack>
                <br></br>
                <Stack>
                  <ContainedButton
                    variant="contained"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Continue
                  </ContainedButton>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      </Grid>

      <Snackbar
        open={snackBarAlert}
        onClose={() => setSnackBarAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ top: "0 !important", left: "0 !important" }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setSnackBarAlert(false)}
          sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            <CancelOutlinedIcon fontSize="small" />
            <Typography variant="body2">{snackBarMessage}</Typography>
          </Stack>
        </Box>
      </Snackbar>
    </div>
  );
}
