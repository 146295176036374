import { BASE_URL } from "../utils/constants";
import { clearAllLocalStorage } from '../utils/localStorageManager';
import handler from "../utils/handler";

export const GetDailySummary = async(value) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/trans/daily-summary?upto=${value}`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}

export const GetDailySummaryBalance = async(value) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/accounts/seller-balance-summary`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}