import React from "react";
import { Typography, Grid, Stack, Box, IconButton } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { DocusealForm } from "@docuseal/react";

const boxStyle = {
	background: "#FFFFFF",
	p: 3,
	boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
	borderRadius: "10px",
};

export default function UploadandSign() {
	return (
		<div>
			<Box sx={boxStyle}>
				<Stack
					direction="row"
					spacing={2}
					sx={{ color: "#093BA7" }}
					alignItems="center"
				>
					<CloudUploadOutlinedIcon />
					<Typography variant="h5">
						Please upload the following documents
					</Typography>
				</Stack>

				<br></br>
				<Grid container justifyContent="flex-start">
					<Grid item container xs={5} direction="row" alignItems="center">
						<Grid item xs={12}>
							<Typography variant="h4" color="neutral.letter_Black">
								Upload NIC/ Passport/ Driving License
							</Typography>
							<br></br>
							<Typography variant="body2" color="neutral.letter_Black">
								Take a photo of the front and back of your NIC/ Passport/
								Driving License. Make sure they are clear and in color:
							</Typography>
							<br></br>
							<Stack direction="row" alignItems="center" spacing={5}>
								<Stack spacing={2} alignItems="center">
									<IconButton>
										<AttachFileOutlinedIcon fontSize="large" />
									</IconButton>
									<Typography variant="body2" color="neutral.letter_Black">
										Front
									</Typography>
								</Stack>
								<Stack spacing={2} alignItems="center">
									<IconButton>
										<AttachFileOutlinedIcon fontSize="large" />
									</IconButton>
									<Typography variant="body2" color="neutral.letter_Black">
										Back
									</Typography>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
					<Grid item xs={1} />
					<Grid item container xs={5} direction="row" alignItems="center">
						<Grid item xs={12}>
							<Typography variant="h4" color="neutral.letter_Black">
								Upload proof of residential address
							</Typography>
							<br></br>
							<Typography variant="body2" color="neutral.letter_Black">
								This could be a bank statment or utility bill. Make sure the
								address, date and logo is cleary visible and not older than
								three months.
							</Typography>
							<br></br>
							<Stack spacing={2} alignItems="flex-start">
								<IconButton>
									<AttachFileOutlinedIcon fontSize="large" />
								</IconButton>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
				<br></br>
			</Box>

			<br></br>
			<Box sx={boxStyle}>
				<Stack
					direction="row"
					spacing={2}
					sx={{ color: "#093BA7" }}
					alignItems="center"
				>
					<CloudUploadOutlinedIcon />
					<Typography variant="h5">Read carefully and Sign</Typography>
				</Stack>
				<Stack
					sx={{height: "20em", overflow: "scroll"}}
				>
					<DocusealForm
						withTitle="false"
						src="https://docuseal.co/d/LEVGR9rhZYf86M"
						email="signer@example.com"
					/>
				</Stack>
				<br></br>
			</Box>
		</div>
	);
}
