import { BASE_URL } from "../utils/constants";
import { clearAllLocalStorage } from '../utils/localStorageManager';
import handler from "../utils/handler";

export const GetMarketplaceRegions = async (value) => {
	const accessToken = localStorage.getItem("accessToken");

	try {

		const headers = {
			"Content-Type": "application/json",
		}
		if (accessToken) {
			headers.Authorization = `Bearer ${accessToken}`
		}

		let response = await fetch(`${BASE_URL}/core/platforms/${value}/regions`, {
			method: "GET",
			headers,
		});

        if (response.status === 401) {
            clearAllLocalStorage();
        };

		let result = handler(response)
		return result

	} catch (e) {
		throw e;
	}
}

export const GetMarketplace = async(platform, region) => {
    const accessToken = localStorage.getItem("accessToken");

    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/platforms/${platform}/regions/${region}/marketplaces`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}