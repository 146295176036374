import { BASE_URL } from "../utils/constants";
import { clearAllLocalStorage } from '../utils/localStorageManager';
import handler from "../utils/handler";

export const PostHelp = async(value) => {
    const accessToken = localStorage.getItem("accessToken");
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/support-tickets`, {
            method: "POST",
            headers,
            body: JSON.stringify(value)
        });

        let result = await response.json();

        return {response, result};
        

    } catch (e) {
        throw e;
    }
}

export const GetSupportHistory = async(value) => {
    const accessToken = localStorage.getItem("accessToken");
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/support-tickets`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}

export const GetSingleSupportHistory = async(value) => {
    const accessToken = localStorage.getItem("accessToken");
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/support-tickets/${value}`, {
            method: "GET",
            headers,
        });

        if (response.status === 401) {
            clearAllLocalStorage();
        };

        let result = handler(response)
        return result
       
    } catch (e) {
        throw e;
    }
}

export const CancelRequest = async(value) => {
    const accessToken = localStorage.getItem("accessToken");
    try {

        const headers = {
            "Content-Type": "application/json",
        }
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        let response = await fetch(`${BASE_URL}/core/support-tickets/update-status`, {
            method: "POST",
            headers,
            body: JSON.stringify(value)
        });


        return response;
    
    } catch (e) {
        throw e;
    }
}