import {
	Box,
	Grid,
	Stack,
	Typography,
	CardContent,
	InputAdornment,
	IconButton,
	Snackbar,
	Alert,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import "./style.css";
import leftImage from "../../assets/loginImage.svg";
import logoColor from "../../assets/logoColor.svg";
import { StyledTextBox } from "../../components/ui/TextBox";
import { StyledCard } from "../../components/ui/Cards";
import { ContainedButton } from "../../components/ui/Buttons";
import {
	OnboardingLogin,
	RequestAccessToken,
} from "../../services/auth.services";
import { NavLink, useNavigate } from "react-router-dom";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Checkbox from "@mui/material/Checkbox";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
// import { StyledCheckboxes } from "../../components/ui/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Login() {
	const [values, setValues] = useState({
		username: "",
		password: "",
	});
	const [showPassword, setShowPassword] = useState(false);
	const [checkPassword, setCheckPassword] = useState(false);
	const [checkUserName, setCheckUserName] = useState(false);

	const [snackBarSuccess, setSnackBarSuccess] = useState(false);
	const [snackBarAlert, setSnackBarAlert] = useState(false);
	const [snackBarAlertMessage, setSnackBarAlertMessage] = useState('');
	const [showRegister, setShowRegister] = useState(false)
	const [amazonCallbackUri, setAmazonCallbackUri] = useState({});

	useEffect(() => {
		const currentUrl = window.location.href;
		const urlParams = new URLSearchParams(currentUrl.split("?")[1]);
		// Create an empty object to store parameters
		const params = {};

		// Loop through all parameters and add them to the object
		for (const [key, value] of urlParams.entries()) {
			params[key] = value;
		}

		// Log the parameters object
		setAmazonCallbackUri(params);
	}, []);

	const matches = useMediaQuery("(min-width:1024px)");

	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		let error = false;

		if (values.username === "") {
			setCheckUserName(true);
			error = true;
			setSnackBarAlert(true);
			setSnackBarAlertMessage('The email you entered is incorrect.');
		} else {
			setCheckUserName(false);
		}

		if (values.password === "") {
			setCheckPassword(true);
			error = true;
			setSnackBarAlert(true);
			setSnackBarAlertMessage('The password you entered is incorrect.');
		} else {
			setCheckPassword(false);
		}

		if (!error) {
			try {
				if (amazonCallbackUri.amazon_callback_uri) {
					OnboardingLogin(values).then((data) => {
						if (data.response.status === 200) {
							data.result.then((result) => {
								window.location.href = decodeURIComponent(
									`${amazonCallbackUri.amazon_callback_uri}?amazon_state=${amazonCallbackUri.amazon_state}&selling_partner_id=${amazonCallbackUri.selling_partner_id}&version=${amazonCallbackUri.version}&state=1-${result.uniqueId}&redirect_uri=https://dev.vepay.io/onboard/consent`
								);
							});
							setSnackBarSuccess(true);
						} else if (data.response.status === 404) {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('User not found.');
							setShowRegister(true);
						} else if (data.response.status === 401) {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('The email or password you entered is incorrect.');
						} else {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('Somthing went wrong');
						}
					});
				} else {
					RequestAccessToken(values).then((data) => {
						if (data.status === 200) {
							//   setSnackBarSuccess(true);
							setTimeout(() => navigate("/dashboard"));
						} else if (data.status === 404) {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('User not found.');
							setShowRegister(true);
						} else if (data.status === 401) {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('The email or password you entered is incorrect.');
						} else {
							setSnackBarAlert(true);
							setSnackBarAlertMessage('Somthing went wrong');
						}
					});
				}
			} catch (error) {
				setSnackBarAlert(true);
				setSnackBarAlertMessage('Somthing went wrong');
				throw error;
			}
		}
	};
	return (
		<div className="main">
			<Grid container alignItems="center" justifyContent="center">
				<StyledCard className="cardMain">
					<CardContent>
						<Grid container alignItems="center" justifyContent="center">
							<Grid item md={6} sx={{ padding: "10%" }}>
								<form onSubmit={(e) => handleSubmit(e)}>
									<img src={logoColor} alt="logo" style={{ width: "25%" }} />
									<br></br>
									<Typography variant="h1">Welcome&nbsp;Back</Typography>
									<br></br>
									{/* <Stack>
				  <Typography variant="body1_light">
					New to VePay?&nbsp;
					<NavLink
						to="/onboarding"
					>
						<span style={{ color: "#093BA7" }}>Create Account</span>
					</NavLink>
				  </Typography>
				</Stack> */}
									<br></br>
									<Stack spacing={2}>
										<Stack>
											<Typography variant="body1_light">Email</Typography>

											<StyledTextBox
												required
												placeholder="Username or Email"
												error={checkUserName}
												value={values.username}
												id="username"
        										name="username"
												autoComplete="username"
												onChange={(e) =>
													setValues({ ...values, username: e.target.value })
												}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<Person2OutlinedIcon sx={{ color: "#868B98" }} />
														</InputAdornment>
													),
												}}
											/>
										</Stack>
										<Stack>
											<Typography variant="body1_light">Password</Typography>
											<StyledTextBox
												required
												placeholder="Password"
												error={checkPassword}
												type={showPassword ? "text" : "password"}
												value={values.password}
												id="password"
        										name="password"
												autoComplete="current-password"
												onChange={(e) =>
													setValues({ ...values, password: e.target.value })
												}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<LockOutlinedIcon sx={{ color: "#868B98" }} />
														</InputAdornment>
													),
													endadornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={() => setShowPassword(!showPassword)}
																edge="end"
															>
																{showPassword ? (
																	<Visibility />
																) : (
																	<VisibilityOff />
																)}
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
										</Stack>
									</Stack>

									{/* <Stack
				  direction="row"
				  justifyContent="space-between"
				  alignItems="center"
				  sx={{ paddingTop: "2%" }}
				>
				  <Stack direction="row" spacing={1}>
					<StyledCheckboxes
					  control={<Checkbox label="Remember me" variant="body2" />}
					></StyledCheckboxes>
					<Typography variant="body2" textAlign="left">
					  Remember me
					</Typography>
				  </Stack>
				  <Typography variant="body2" sx={{ color: "#093BA7" }}>
					Forget Password
				  </Typography>
				</Stack> */}
									<br></br>
									<br></br>
									<Stack>
										<ContainedButton
											variant="contained"
											// onClick={(e) => handleSubmit(e)}
											type="submit"
										>
											Sign In
										</ContainedButton>
									</Stack>
								</form>
							</Grid>
							<Grid item md={6} sx={{ display: matches ? "flex" : "none" }}>
								<img
									src={leftImage}
									alt="Grow your business"
									style={{ width: "100%" }}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</StyledCard>
			</Grid>
			<Snackbar
				open={snackBarSuccess}
				onClose={() => setSnackBarSuccess(false)}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				sx={{ top: "0 !important", left: "0 !important" }}
				autoHideDuration={6000}
			>
				<Box
					onClose={() => setSnackBarSuccess(false)}
					sx={{ width: "100vw", background: "#00AD47", p: 0.5 }}
				>
					<Stack
						direction="row"
						spacing={2}
						sx={{
							width: "100%",
							textAlign: "center",
							alignItems: "center",
							justifyContent: "center",
							color: "#FFFFFF",
						}}
					>
						<CheckCircleOutlineOutlinedIcon fontSize="small" />{" "}
						<Typography variant="body2">
							Authentification succsessful!
						</Typography>
					</Stack>
				</Box>
			</Snackbar>

			<Snackbar
				open={showRegister}
				onClose={() => setShowRegister(false)}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			>
				<Alert severity="error" alignitems="center">
					<Stack
						direction="row"
						spacing={2}
						alignitems="center"
					>
						<Typography variant="h5">
							Account not found. <NavLink to="/onboarding" sx={{ textdecoration: 'none' }}>
								<span style={{ color: "#2294DD" }}>Create Account</span>
							</NavLink>
						</Typography>
					</Stack>
				</Alert>
			</Snackbar>

			<Snackbar
				open={snackBarAlert}
				onClose={() => setSnackBarAlert(false)}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				sx={{ top: "0 !important", left: "0 !important" }}
				autoHideDuration={6000}
			>
				<Box
					onClose={() => setSnackBarAlert(false)}
					sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
				>
					<Stack
						direction="row"
						spacing={2}
						sx={{
							width: "100%",
							textAlign: "center",
							alignItems: "center",
							justifyContent: "center",
							color: "#FFFFFF",
						}}
					>
						<CancelOutlinedIcon fontSize="small" />{" "}
						<Typography variant="body2">
							{snackBarAlertMessage}
						</Typography>
					</Stack>
				</Box>
			</Snackbar>
		</div>
	);
}
