import { createTheme } from '@mui/material/styles';

// import '@fontsource/open-sans';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import "@fontsource/montserrat";

const fontFamily = '"Open Sans", sans-serif';

export const Theme = createTheme({
  typography: {
    h1_large: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '3.1em', //56px - 3.125em - 3vw
      lineHeight: '32.68px'
  
    },
    h1: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '2em', //36px - 2.25em - 1.9vw
      letterSpacing: '2px'
    },
    h1_light: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '2em', //36px - 2.25em - 1.9vw
      letterSpacing: '2px',
      lineHeight: 1
    },
    h2: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.8em', //32px - 2em - 1.7vw
    },
    h2_light: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '24px', //32px - 2em - 1.7vw
      lineHeight: '32.68px',
      letterSpacing: '2px'
    },
    h3: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.5em', //28px - 1.75em - 1.5vw
      lineHeight: '2.25em'
    },
    h4: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1.1em', //22px - 1.375em - 1.3vw
      lineHeight: '1.8125em',
      letterSpacing: '1px'
    },
    h4_bold: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.1em', //22px - 1.375em - 1.3vw
      lineHeight: '1.8125em',
      letterSpacing: '1px'
    },
    h5: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.9em', //18px - 1.125em - 0.9vw
      lineHeight: '1.4375em',
      letterSpacing: '1px'
    },

    h5_bold: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '0.9em', //18px - 1.125em - 0.9vw
      lineHeight: '1.4375em',
      letterSpacing: '1px'
    },

    h5_light: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1em', //18px - 1.125em - 0.9vw
      lineHeight: '2.4375em',
      letterSpacing: '2px'
    },

    body1: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.9em', //16px - 1em - 0.7vw
      lineHeight: '1.3125em',
    },

    body1_light: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.9em', //16px - 1em - 0.7vw
      lineHeight: '1.3125em'
    },

    body2: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px', //14px - 0.875em - 0.5vw
      lineHeight: '1.125em'
    },

    body2_light: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px', //14px - 0.875em - 0.5vw
      lineHeight: '1.125em'
    },

    body3: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.6em', //12px - 0.75em - 0.3vw
      lineHeight: '1em'
    },
    body4: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.5em', //10px - 0.62em - 0.2vw
      lineHeight: '1em'
    },

    amountsFont: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px', //16px - 1em - 0.7vw
      lineHeight: '1.3125em',
      letterSpacing: '2px'
    },

    amountsFontBold: {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px', //16px - 1em - 0.7vw
      lineHeight: '1.3125em',
      letterSpacing: '2px'
    }

  },
  

  palette: {
    neutral: {
        letter_Black: '#28282A',
        Black_Lite: '#4E5055',
        Gray_Dark: '#868B98',
        Gray_Lite: '#D6D9E1',
        White_Bg_Lite: '#F6F6F6',
        White_Bg_Dark: '#FAFAFA',
        Pure_White_Bg: '#FFFFFF'
    },

    primary: {
        main: '#093BA7',
        Blue_Dark: '#032878',
        Primary_Blue: '#093BA7',
        Primary_Lite: '#0096DA',
        Blue_Lite: '#4471D3',
        Blue_Bg_Dark: '#CAD7F4',
        Blue_Bg_Lite: '#F1F5FC'
    },

    success: {
        main: '#00AD47',
        Success_Dk: '#00AD47',
        Success_Lit: '#ECFDF3'
    },

    warning: {
        main: '#FF9F43',
        Warning_Dk: '#FF9B43',
        Warning: '#FF9F43',
        Warning_Lit: '#FFEAD6'
    },

    danger: {
        main: '#F33535',
        Danger_Dk: '#F33535',
        Danger: '#EA5455',
        Danger_Lit: '#FFD6D6'
    }
  }

});