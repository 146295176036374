import React from "react";
import { useState } from "react";
import { GreenContainedButton } from "./ui/Buttons";
import {
  Typography,
  Box,
  // IconButton, 
  Grid,
  Stack
} from "@mui/material";
// import { KeyboardArrowRight, KeyboardArrowDown } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useOutletContext } from "react-router-dom";
import WithdrawModal from "./WithdrawModal";
import { formatCurrency } from "../utils/utilityFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingBox } from "./ui/Box";
import { GetWithdraw } from "../services/withdrawal.services";

export default function WithdrawCard() {
  const matches = useMediaQuery("(min-width:768px)");
  // const [open, setOpen] = useState(
  //   window.location.pathname === "/dashboard" || window.location.pathname === "/" ? false : true
  // );
  const [userData, accountBalance, summaryBalance] = useOutletContext();
  const [withdrawData, setWithdrawData] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    GetWithdraw().then((data) => {
      setWithdrawData(data);
      setOpenModal(true);
    });
  };

  const setModalCallback = (data) => {
    setOpenModal(data);
  };

  const pathName = window.location.pathname;

  if (!accountBalance) {
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    );
  }

  const boxStyle = {
    p: 3,
    backgroundColor: "#FFFFFF",
    height: "100%",
    boxShadow: "0px 0px 4px rgba(3, 40, 120, 0.18)",
    borderRadius: "2px",
    display: matches ? { xs: "none", sm: "flex" } : { xs: "flex", sm: "none" },
  };

  let welcomeMessage = "";

  var DayTimedata = [
    [22, "Working Late"],
    [18, "Good Evening"],
    [12, "Good Afternoon"],
    [5, "Good Morning"],
    [0, "Whoa, Early Bird"],
  ],
    hr = new Date().getHours();
  for (var i = 0; i < DayTimedata.length; i++) {
    if (hr >= DayTimedata[i][0]) {
      welcomeMessage = DayTimedata[i][1];
      break;
    }
  }

  return (
    <div>
      {pathName === '/dashboard' || pathName === '/' ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={boxStyle}>
              <Grid
                container
                justifyContent="space-around"
                spacing={2}
                alignItems="flex-start"
              >
                {/* <Grid item xs={2}>
                <IconButton onClick={() => setOpen(!open)} sx={{ mb: 1, pt: open ? 0 : 1 }}>
                  {open ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
                </IconButton>
              </Grid> */}
                <Grid item xs={10} rowSpacing={3}>
                  <Grid container alignItems="baseline" spacing={1}>
                    <Grid item xs="auto">
                      <Typography variant="h2_light">
                        {welcomeMessage},
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography variant="h2">{userData || 'John Doe'}</Typography>
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      sm={12}
                      md={6}
                    // sx={{ display: open ? "none" : "" }}
                    >
                      <Typography variant="h5" sx={{ color: "#868B98" }}>
                        Service Type: <span style={{ color: "#2F5DC2" }}>InstaPay</span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={6}
                    // sx={{ display: open ? "none" : "" }}
                    >
                      <Typography variant="h5" sx={{ color: "#868B98" }}>
                        Status: <span style={{ color: "#2F5DC2" }}>Active</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={boxStyle}>
              <Grid
                container
                justifyContent="space-around"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={6} >
                  <Stack textAlign="left">
                    <Typography
                      variant="h5_light"
                      sx={{
                        color: "#909090",
                        // display: !open ? "" : "none", 
                      }}
                    >
                      Available Balance
                    </Typography>
                    <Typography variant={accountBalance.totalAvailableBalance <= 0 ? "h1_light" : "h1"} sx={{ color: accountBalance.totalAvailableBalance <= 0 ? "black" : "#00AD47" }}>
                      {formatCurrency(accountBalance.totalAvailableBalance, true)}
                    </Typography>
                  </Stack>

                </Grid>

                <Grid item xs={12} sm={12} md={5} justifyContent="flex-end">
                  <GreenContainedButton
                    variant="contained"
                    fullWidth
                    onClick={handleClickOpen}
                    disabled={accountBalance.totalAvailableBalance <= 0 ? true : false}
                  >
                    Withdraw
                  </GreenContainedButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : ''}
      {pathName === '/daily-summary' || pathName === '/withdrawals' || pathName === '/transaction-breakdown' ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={8}>
            <Box sx={boxStyle}>
              <Grid
                container
                justifyContent="space-between"
                spacing={2}
                alignItems="flex-end"
              >
                <Grid item xs={12} sm={12} md="auto" textAlign="left">
                  <Typography variant={accountBalance.totalAvailableBalance <= 0 ? "h1_light" : "h1"} sx={{ color: accountBalance.totalAvailableBalance <= 0 ? "black" : "#00AD47" }}>
                    {formatCurrency(accountBalance.totalAvailableBalance, true)}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#868B98",
                      // display: !open ? "" : "none", 
                      mb: 1.5,
                      mt: 1
                    }}
                  >
                    Available Balance
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md="auto">

                  <Typography variant="h5">
                    {formatCurrency(accountBalance.totalReceivableBalance, true)}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#868B98",
                      // display: !open ? "" : "none", 
                      mb: 1.5,
                      mt: 1
                    }}
                  >
                    Receivable&nbsp;Amount
                  </Typography>

                </Grid>
                <Grid item xs={12} sm={6} md="auto">

                  <Typography variant="h5">
                    {formatCurrency(accountBalance.totalMarketplaceBalance, true)}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#868B98",
                      // display: !open ? "" : "none", 
                      mb: 1.5,
                      mt: 1
                    }}
                  >
                    Marketplace&nbsp;Balance
                  </Typography>

                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={4}>
                    <GreenContainedButton
                      sx={{ ml: 1.5}}
                      variant="contained"
                      fullWidth
                      onClick={handleClickOpen}
                      disabled={accountBalance.totalAvailableBalance <= 0 ? true : false}
                    >
                      Withdraw
                    </GreenContainedButton>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={boxStyle}>
              <Grid
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Stack spacing={1} textAlign="left">
                  <Stack >
                    <Typography variant="body1">
                      {formatCurrency(summaryBalance.pendingTransfers, true)}
                    </Typography>
                    <Typography variant="body1_light">Pending Withdrawal Amount &nbsp;</Typography>
                  </Stack>
                  <Stack >
                    <Typography variant="body1">
                      {formatCurrency(summaryBalance.netAvailableBalance, true)}
                    </Typography>
                    <Typography variant="body1_light">Current Available Amount &nbsp;</Typography>

                  </Stack>
                  <Stack>
                    <Typography variant="body1">
                      {formatCurrency(summaryBalance.pendingCardTransactionBalance, true)}
                    </Typography>
                    <Typography variant="body1_light">Pending Card Transaction &nbsp;</Typography>

                  </Stack>
                </Stack>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : ''}
      <WithdrawModal status={openModal} setModalCallback={setModalCallback} accountBalance={accountBalance} withdrawData={withdrawData} />
    </div>
  );
}
