import React, { useRef } from "react";
import { useState, useEffect } from "react";
// import { Typography, Stack, Box, Grid, Badge, Tooltip } from "@mui/material";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LoadingBox } from "../../components/ui/Box";
import WithdrawCard from "../../components/Withdraw";
import {
  GetDashboardData,
} from "../../services/dashboard.service";
import CircularProgress from "@mui/material/CircularProgress";
// import { formatCurrency } from "../../utils/utilityFunctions";
import { useOutletContext } from "react-router-dom";
import TransactionDetails from "./TransactionDetails";

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [accountBalance] = useOutletContext();
  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;
  
    const fetchData = async () => {
      setIsLoading(false);
      try {
        await GetDashboardData().then((data) => {
          setIsLoading(true);
          setDashboardData(data);
        });
        setIsLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchData();
  }, []);

  if (!dashboardData || !accountBalance)
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    );

  // const boxStyle = {
  //   pt: 3,
  //   pr: 3,
  //   pl: 3,
  //   boxShadow: "0px 0px 4px rgba(3, 40, 120, 0.18)",
  //   borderRadius: "2px",
  //   width: "100%",
  // };

  return (
    <div>
      <WithdrawCard />
      <br></br>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <Box sx={[boxStyle, { backgroundColor: "#F0FFFF" }]}>
            <Stack alignItems="flex-end">
              <Typography variant="h5" sx={{ color: "#28282A", opacity: 0.7 }}>
                Total Available Balance &nbsp;
                <Badge
                  badgeContent={
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="Current funds ready for withdrawal."
                    >
                      <InfoOutlinedIcon
                        sx={{
                          color: "#989898",
                          marginLeft: "100%",
                          fontSize: 16
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Typography>
              <Typography variant="h1_large">
                {formatCurrency(accountBalance.totalAvailableBalance)}
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <Box sx={[boxStyle, { backgroundColor: "#ADD8E6" }]}>
            <Stack alignItems="flex-end">
              <Typography variant="h5" sx={{ color: "#28282A", opacity: 0.7 }}>
                Total Receivable Balance &nbsp;
                <Badge
                  badgeContent={
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="Total net balance receivable after marketplace and VePay Fee deductions. The remaining balance consist of Agancy fee and Incentive fee receivable to the client."
                    >
                      <InfoOutlinedIcon
                        sx={{
                          color: "#989898",
                          marginLeft: "100%",
                          fontSize: 16
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Typography>
              <Typography variant="h1_large">
                {formatCurrency(accountBalance.totalReceivableBalance)}
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <Box sx={[boxStyle, { backgroundColor: "#AFEEEE" }]}>
            <Stack alignItems="flex-end">
              <Typography variant="h5" sx={{ color: "#28282A", opacity: 0.7 }}>
                Total Marketplace Balance &nbsp;
                <Badge
                  badgeContent={
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="Total funds receivable from the Marketplaces post-deduction."
                    >
                      <InfoOutlinedIcon
                        sx={{
                          color: "#989898",
                          marginLeft: "100%",
                          fontSize: 16
                        }}
                        fontSize="small"
                      />
                    </Tooltip>
                  }
                />
              </Typography>

              <Typography variant="h1_large">
                {formatCurrency(accountBalance.totalMarketplaceBalance)}
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid> */}
      <br></br>
      {isLoading ? (
        <LoadingBox>
          <CircularProgress />
        </LoadingBox>
      ) : (
        ""
      )}
      {dashboardData.map((data, index) => (
        <React.Fragment key={index}>
          <TransactionDetails key={index} data={data} />
          <br></br>
        </React.Fragment>
      ))}
      <br></br>
    </div>
  );
}
