import { Grid, Stack, Typography, CardContent, Snackbar, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { StyledCard } from "./ui/Cards";
import { ContainedButton } from "./ui/Buttons";
import { useNavigate } from "react-router-dom";
import ConnectedLogo from "../assets/connected.svg";
import failedLogo from "../assets/failed.svg"
import { PlatformAuth } from "../services/auth.services";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LoadingBox } from "./ui/Box";

export default function MarketplaceConnected() {
  const [snackBarAlert, setSnackBarAlert] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(true);
  const hasRun = useRef(false);

  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get("state");
  const mwsAuthToken = urlParams.get("mws_auth_token") || '';
  const sellingPartnerId = urlParams.get("selling_partner_id");
  const spApiOauthCode = urlParams.get("spapi_oauth_code");

  const navigate = useNavigate();

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    const value = {
      state,
      mwsAuthToken,
      sellingPartnerId,
      spApiOauthCode,
    };
    const fetchData = async () => {
      try {
        setStatus(true);
        await PlatformAuth(value).then(() => {
            setStatus(false);
            setError(false);
        });
        
      } catch (error) {
        setSnackBarAlert(true);
        setStatus(false);
        setError(true);
        setSnackBarMessage("Something went wrong, please try again");
        throw error;
      }
    };

    fetchData();
  }, [state, mwsAuthToken, sellingPartnerId, spApiOauthCode])

  const handleSubmit = () => {
    setTimeout(() => navigate(`/onboarding?markeplacelinked=${true}`), 2000);
  };

  const handleFailer = () => {
    setTimeout(() => navigate(`/onboarding?markeplacelinked=${false}`), 2000);
  };

  return (
    <div className="main">
      <Grid container alignItems="center" justifyContent="center">
        <StyledCard sx={{ width: "50%" }}>
          <CardContent>
            {status ? (
              <LoadingBox>
                <CircularProgress />
              </LoadingBox>
            ) : ( error ? ( <Grid container alignItems="center" justifyContent="center">
              <Grid item md={12} sx={{ padding: "10%" }} textAlign="center">
                <Typography variant="h1">Connection Failed</Typography>
                <br></br>
                <img src={failedLogo} alt="logo" style={{ width: "50%" }} />
                <br></br>
                <br></br>
                <Stack>
                  <Typography variant="body1_light">
                  We were unable to connect to your Amazon store. Please check your connection settings and try again.
                  </Typography>
                </Stack>
                <br></br>
                <Stack>
                  <ContainedButton
                    variant="contained"
                    onClick={(e) => handleFailer(e)}
                  >
                    Try Again
                  </ContainedButton>
                </Stack>
              </Grid>
            </Grid>):( <Grid container alignItems="center" justifyContent="center">
            <Grid item md={12} sx={{ padding: "10%" }} textAlign="center">
              <Typography variant="h1">Marketplace Connected</Typography>
              <br></br>
              <img src={ConnectedLogo} alt="logo" style={{ width: "50%" }} />
              <br></br>
              <br></br>
              <Stack>
                <Typography variant="body1_light">
                  Your Amazon store has been connected
                  and data has been transferred. You are all set to get
                  factoring.
                </Typography>
              </Stack>
              <br></br>
              <Stack>
                <ContainedButton
                  variant="contained"
                  onClick={(e) => handleSubmit(e)}
                >
                  Continue
                </ContainedButton>
              </Stack>
            </Grid>
          </Grid>
          ))}
          </CardContent>
        </StyledCard>
      </Grid>

      <Snackbar
        open={snackBarAlert}
        onClose={() => setSnackBarAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ top: "0 !important", left: "0 !important" }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setSnackBarAlert(false)}
          sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            <CancelOutlinedIcon fontSize="small" />
            <Typography variant="body2">{snackBarMessage}</Typography>
          </Stack>
        </Box>
      </Snackbar>
    </div>
  );
}
