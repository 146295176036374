import {
  Typography,
  Grid,
  Stack,
  MenuItem,
  Box,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import { StyledTextBoxv2 } from "../../components/ui/TextBox";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PhoneInput from "react-phone-input-2";
import { GreenContainedButton } from "../../components/ui/Buttons";
import { PostBusinessInfomation } from "../../services/auth.services";
// import { geocodeByAddress } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const boxStyle = {
  background: "#FFFFFF",
  p: 3,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
  borderRadius: "10px",
};

export default function BusinessInfo({ updateStep }) {
  const [businessInfo, setbusinessInfo] = useState({
    name: {
      value: "",
      error: false,
      message: "Business Name is required",
    },
    industry: {
      value: "FINANCIAL_SERVICES",
      error: false,
      message: "Segment is required",
    },
    connectedMarketPlaces: {
      value: "AMAZON",
      error: false,
      message: "Connected Marketplaces is required",
    },
    contactNo: {
      value: "",
      error: false,
      message: "Contact Number is required",
    },
    email: {
      value: "",
      error: false,
      message: "Email is required",
    },
    address: {
      value: "",
      error: false,
      message: "Address is required",
    },
    city: {
      value: "",
      error: false,
      message: "City is required",
    },
    state: {
      value: "",
      error: false,
      message: "State is required",
    },
    zipCode: {
      value: "",
      error: false,
      message: "Zip Code is required",
    },
    country: {
      value: "",
      error: false,
      message: "Country is required",
    },
    userUniqueId: {
      value: localStorage.getItem("uniqueId"),
      error: false,
      message: "Contact Number is required",
    },
  });
  const [inputAddress, setInputAddress] = useState(null);
  const [snackBarAlert, setSnackBarAlert] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const addressAPIKey = null;
  //AIzaSyADsyiSRV3CsnVV71hbkuwZhXhVU4kgeMs

  // useEffect(() => {
  //   if (inputAddress) {
  //     geocodeByAddress(inputAddress.label).then((place) => {
  //       let updatedBusinessInfo = { ...businessInfo, address: { value: inputAddress.label} }; // Create a copy of userInfo
        
  //       for (var i = 0; i < place[0].address_components.length; i++) {
  //         var addressType = place[0].address_components[i].types[0];

  //         switch (addressType) {
  //           case 'locality':
  //             updatedBusinessInfo.city.value = place[0].address_components[i].long_name;
  //             break;
  //           case 'administrative_area_level_1':
  //             updatedBusinessInfo.state.value = place[0].address_components[i].long_name;
  //             break;
  //           case 'country':
  //             updatedBusinessInfo.country.value = place[0].address_components[i].long_name;
  //             break;
  //           case 'postal_code':
  //             updatedBusinessInfo.zipCode.value = place[0].address_components[i].long_name;
  //             break;
  //           default:
  //               break;
  //         }
  //       }

  //       setbusinessInfo(updatedBusinessInfo); // Update state once after loop
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [inputAddress])

  const handleSubmit = (e) => {
    e.preventDefault();

    // let error = false;
    const updatedbusinessInfo = { ...businessInfo };
    const propertyValues = Object.fromEntries(
      Object.entries(updatedbusinessInfo).map(([key, value]) => [
        key,
        value.value,
      ])
    );

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    for (const key in updatedbusinessInfo) {
      if (
        !updatedbusinessInfo[key].value ||
        updatedbusinessInfo[key].value.trim() === ""
      ) {

        updatedbusinessInfo[key].error = true;
        setbusinessInfo({
          ...businessInfo,
          [key]: { ...businessInfo.error, error: true },
        });
        // error = true;
        setSnackBarMessage("Please fill all fields");
      } else {
        updatedbusinessInfo[key].error = false;
        // error = false;
        if (
          key === "email" &&
          !emailRegex.test(updatedbusinessInfo[key].value)
        ) {
          updatedbusinessInfo[key].error = true;
          // error = true;
        }
      }
    }

    // if (!error) {
      try {
        PostBusinessInfomation(propertyValues).then((data) => {
          if (data.status === 200) {
            updateStep(3);
          } else if (data.status === 500) {
            setSnackBarAlert(true);
            setSnackBarMessage("Email already exists");
          } else if (data.status === 400) {
            setSnackBarAlert(true);
            setSnackBarMessage("Please fill all fields");
          } else {
            setSnackBarMessage("Somthing went wrong please try again");
          }
        });
      } catch (error) {
        setSnackBarAlert(true);
        setSnackBarMessage("Somthing went wrong please try again");
        throw error;
      }
    // }
  };
  return (
    <div>
      <br></br>
      <Box sx={boxStyle}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ color: "#093BA7" }}
          alignItems="center"
        >
          <FolderSharedIcon />
          <Typography variant="h5">
            Basic Information for Business Profile
          </Typography>
        </Stack>

        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Business Name
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="Business Name"
                value={businessInfo.name.value}
                onChange={(event) =>
                  setbusinessInfo({
                    ...businessInfo,
                    name: { ...businessInfo.name, value: event.target.value },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {businessInfo.name.error ? businessInfo.name.message : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Segment
              </Typography>
              <StyledTextBoxv2
                fullWidth
                value={businessInfo.industry.value}
                select
                onChange={(event) =>
                  setbusinessInfo({
                    ...businessInfo,
                    industry: {
                      ...businessInfo.industry,
                      value: event.target.value,
                    },
                  })
                }
              >
                <MenuItem value="FINANCIAL_SERVICES">
                  Financial Services
                </MenuItem>
                <MenuItem value="E_COMMERCE">E-Commerce</MenuItem>
                <MenuItem value="EDUCATION">Education</MenuItem>
                <MenuItem value="HEALTH_AND_SERVICES">
                  Health & Services
                </MenuItem>
                <MenuItem value="OTHER">Other</MenuItem>
              </StyledTextBoxv2>
              <Typography variant="body3" color="red">
                {businessInfo.industry.error
                  ? businessInfo.industry.message
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Connected Market Places
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="Connected Market Places"
                value={businessInfo.connectedMarketPlaces.value}
                onChange={(event) =>
                  setbusinessInfo({
                    ...businessInfo,
                    connectedMarketPlaces: {
                      ...businessInfo.connectedMarketPlaces,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {businessInfo.connectedMarketPlaces.error
                  ? businessInfo.connectedMarketPlaces.message
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <br></br>
      <Box sx={boxStyle}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ color: "#093BA7" }}
          alignItems="center"
        >
          <LocalPhoneIcon />
          <Typography variant="h5">Business Contact Information</Typography>
        </Stack>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Contact Number
              </Typography>
              {businessInfo.contactNo.error ? (
                <PhoneInput
                  country={"us"}
                  inputStyle={{
                    borderColor: "#d2302f",
                  }}
                  autoFormat={true}
                  value={businessInfo.contactNo.value}
                  onChange={(value) =>
                    setbusinessInfo({
                      ...businessInfo,
                      contactNo: { ...businessInfo.contactNo, value },
                    })
                  }
                />
              ) : (
                <PhoneInput
                  country={"us"}
                  autoFormat={true}
                  value={businessInfo.contactNo.value}
                  onChange={(value) =>
                    setbusinessInfo({
                      ...businessInfo,
                      contactNo: { ...businessInfo.contactNo, value },
                    })
                  }
                />
              )}

              <Typography variant="body3" color="red">
                {businessInfo.contactNo.error
                  ? businessInfo.contactNo.message
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Email
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="example@gmail.com"
                value={businessInfo.email.value}
                onChange={(event) =>
                  setbusinessInfo({
                    ...businessInfo,
                    email: { ...businessInfo.email, value: event.target.value },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {businessInfo.email.error ? businessInfo.email.message : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Business Address
              </Typography>
               {addressAPIKey ? (
               <GooglePlacesAutocomplete
               apiKey="AIzaSyADsyiSRV3CsnVV71hbkuwZhXhVU4kgeMs"
               selectProps={{
                 inputAddress,
                 onChange: setInputAddress,
                 styles: {
                   control: (provided) => ({
                     ...provided,
                     border: "none",
                     borderRadius: "4px",
                     height: "2.5em",
                     background: '#F9F9FB',
                     color: "#28282A",
                   }),
                 },
               }}
             />
              ) : (
                <StyledTextBoxv2
                  fullWidth
                  value={businessInfo.address.value}
                  placeholder="Enter Address"
                  onChange={(event) =>
                    setbusinessInfo({
                      ...businessInfo,
                      address: {
                        ...businessInfo.address,
                        value: event.target.value,
                      },
                    })
                  }
                />
              )}
              <Typography variant="body3" color="red">
                {businessInfo.address.error ? businessInfo.address.message : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                City
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="Phoenix"
                value={businessInfo.city.value}
                onChange={(event) =>
                  setbusinessInfo({
                    ...businessInfo,
                    city: { ...businessInfo.city, value: event.target.value },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {businessInfo.city.error ? businessInfo.city.message : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                State
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="Arizona"
                value={businessInfo.state.value}
                onChange={(event) =>
                  setbusinessInfo({
                    ...businessInfo,
                    state: { ...businessInfo.state, value: event.target.value },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {businessInfo.state.error ? businessInfo.state.message : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Zip code
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="10008"
                value={businessInfo.zipCode.value}
                onChange={(event) =>
                  setbusinessInfo({
                    ...businessInfo,
                    zipCode: {
                      ...businessInfo.zipCode,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {businessInfo.zipCode.error ? businessInfo.zipCode.message : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Country
              </Typography>
              <StyledTextBoxv2 fullWidth placeholder="United State" value={businessInfo.country.value}
                onChange={(event) =>
                  setbusinessInfo({
                    ...businessInfo,
                    country: {
                      ...businessInfo.country,
                      value: event.target.value,
                    },
                  })
                } />
                 <Typography variant="body3" color="red">
                {businessInfo.country.error ? businessInfo.country.message : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <br></br>
      <Stack justifyContent="flex-end" alignItems="flex-end">
        <GreenContainedButton
          variant="contained"
          onClick={(e) => handleSubmit(e)}
          endIcon={<ArrowForwardIosIcon />}
        >
          Next
        </GreenContainedButton>
      </Stack>
      <br></br>
      <Snackbar
        open={snackBarAlert}
        onClose={() => setSnackBarAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ top: "0 !important", left: "0 !important", zIndex: 1 }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setSnackBarAlert(false)}
          sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            <CancelOutlinedIcon fontSize="small" />
            <Typography variant="body2">{snackBarMessage}</Typography>
          </Stack>
        </Box>
      </Snackbar>
      <br></br>
    </div>
  );
}
