import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      marginTop: "5px",
      height: "2.5em",
      width: "28em",
      background: '#F9F9FB',
    "& > fieldset": {
        border: "none"
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
     
    },
    "& .MuiFormLabel-root": {
      color: "#868B98",
    },
    "& .MuiInputBase-root": {
      color: "#28282A",
    },
    "& .MuiOutlinedInput-input": {
      padding: '0.5em',
    },
}));