import React from "react";
import { Typography, Stack, Box } from "@mui/material";
// import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
// import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

const boxStyle = {
  background: "#FFFFFF",
  p: 3,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
  borderRadius: "10px",
};

export default function Completed() {
  return (
    <div>
      <Box sx={boxStyle}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ color: "#093BA7" }}
          alignItems="center"
        >
          <Typography variant="h5">Done!</Typography>
        </Stack>
        <br></br>
        <Stack spacing={3}>
          <Typography variant="body1_light">
            Congratulation's on completing the onboarding process of Vepay Inc's
            ethical and Halal factoring solutions. We have successfully received
            your application, and our team will now review it within 3 to 5
            business days.
          </Typography>
          <Typography variant="body1_light">
            Should we acquire any additional information, we will promplty reach
            out to you. We appreciate the trust you have placed in our services,
            and we are excited to support your business.
          </Typography>
          <Typography variant="body1_light">
            Once your application is approved, you will recevice an email with
            access to your dedicated portal portal. This portal will provide you
            with a convenient platform to manage your factoring needs. We are
            confident that our ethical factoring services will contribute to
            your business success.
          </Typography>
          <Typography variant="body1_light">
            If you have any questions or need further assistance, please feel
            free to contact us at <b>support@vepay.io</b>. Thanks you once again
            for choosing VePay as your ethical factoring partner. We look
            forward to a fruitfull partnership.
          </Typography>
        </Stack>

        <br></br>
      </Box>
    </div>
  );
}
