import { styled } from "@mui/material/styles";
import { TextField} from "@mui/material";

export const StyledTextBox = styled(TextField)(({ theme }) => ({
  width: '100%',
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    height: "2.5em",
  },
  "& .MuiFormLabel-root": {
    color: "#868B98",
  },
  "& .MuiInputBase-root": {
    color: "#28282A",
  },
  "& .MuiOutlinedInput-input": {
    padding: '0.5em',
  },
}));

export const StyledTextBoxLarge = styled(TextField)(({ theme }) => ({
  width: '100%',
  fontSize: '1.4em',
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
  },
  "& .MuiFormLabel-root": {
    color: "#868B98",
  },
  "& .MuiInputBase-root": {
    color: "#28282A",
  },
}));

export const StyledTextBoxv2 = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    height: "2.5em",
    background: '#F9F9FB',
  "& > fieldset": {
      border: "none"
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
   
  },
  "& .MuiFormLabel-root": {
    color: "#868B98",
  },
  "& .MuiInputBase-root": {
    color: "#28282A",
  },
  "& .MuiOutlinedInput-input": {
    padding: '0.5em',
  },
}));

export const NoBorderTextBox = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    height: "2.5em",
    border: "0px none",
    background: "#F9F9FB"
  },
  '& .MuiOutlinedInput-notchedOutline': {
   
  },
  "& .MuiFormLabel-root": {
    color: "#868B98",
  },
  "& .MuiInputBase-root": {
    color: "#28282A",
  },
}));

export const NoBorderTextArea = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    // height: "2.5em",
    border: "0px none",
    background: "#F9F9FB"
  },
  '& .MuiOutlinedInput-notchedOutline': {
   
  },
  "& .MuiFormLabel-root": {
    color: "#868B98",
  },
  "& .MuiInputBase-root": {
    color: "#28282A",
  },
}));