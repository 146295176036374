// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/LoginBackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 0 5%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
}
.main .cardMain {
  width: 60%;
}
@media (max-width: 480px) {
  .main .cardMain {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .main .cardMain {
    width: 70%;
  }
}
@media (max-width: 1024px) {
  .main .cardMain {
    width: 60% !important;
  }
}
.main .logo {
  width: 25%;
}
@media (max-width: 480px) {
  .main .logo {
    width: 25% !important;
  }
}
@media (max-width: 768px) {
  .main .logo {
    width: 15% !important;
  }
}
@media (max-width: 1024px) {
  .main .logo {
    width: 25%;
  }
}
.main .formContainer {
  padding: 7% 10%;
}`, "",{"version":3,"sources":["webpack://./src/view/login/login.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAGA;EACC,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,yDAAA;EACA,sBAAA;EACA,2BAAA;AAFD;AAIC;EACC,UAAA;AAFF;ACPE;EDQD;IAIE,WAAA;EADD;AACF;ACZE;EDQD;IAQE,UAAA;EAAD;AACF;ACjBE;EDQD;IAYE,qBAAA;EACD;AACF;AAEC;EACC,UAAA;AAAF;ACzBE;EDwBD;IAIE,qBAAA;EACD;AACF;AC9BE;EDwBD;IAQE,qBAAA;EAED;AACF;ACnCE;EDwBD;IAYE,UAAA;EAGD;AACF;AAAC;EACC,eAAA;AAEF","sourcesContent":["@import \"../../styles/mixins\";\n@import \"../../styles/variables\";\n\n.main {\n\tmin-height: 100vh;\n\tdisplay: flex;\n\tjustify-content: center;\n\tpadding: 0 5%;\n\tbackground-image: url(\"../../assets/LoginBackground.png\");\n\tbackground-size: cover;\n\tbackground-position: center;\n\n\t.cardMain {\n\t\twidth: 60%;\n\n\t\t@include responsive($mobile) {\n\t\t\twidth: 100%;\n\t\t}\n\n\t\t@include responsive($tablet) {\n\t\t\twidth: 70%;\n\t\t}\n\n\t\t@include responsive($laptop) {\n\t\t\twidth: 60% !important;\n\t\t}\n\t}\n\n\t.logo {\n\t\twidth: 25%;\n\n\t\t@include responsive($mobile) {\n\t\t\twidth: 25% !important;\n\t\t}\n\n\t\t@include responsive($tablet) {\n\t\t\twidth: 15% !important;\n\t\t}\n\n\t\t@include responsive($laptop) {\n\t\t\twidth: 25%;\n\t\t}\n\t}\n\n\t.formContainer {\n\t\tpadding: 7% 10%;\n\t}\n}\n","@import \"./variables\"; // Import variables first\n\n@mixin responsive($breakpoint, $type: max) {\n\t@if $type ==max {\n\t\t@media (max-width: $breakpoint) {\n\t\t\t@content;\n\t\t}\n\t}\n\n\t@else if $type ==min {\n\t\t@media (min-width: $breakpoint) {\n\t\t\t@content;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
