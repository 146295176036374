import { styled } from "@mui/material/styles";
import {TableContainer, TableCell, TableRow} from '@mui/material';

export const StyledTableContainer = styled (TableContainer)(({ theme }) => ({
  borderRadius: "0px 0px 0px 0px",
  boxSizing:"border-box",
  background:'#FFFFFF',
  border:'1px solid #C8D2D8',
}));

export const StyledTableCell = styled (TableCell)(({ theme }) => ({
    color: 'theme.palette.neutral.letter_Black',
    fontWeight: 600,
    fontSize: '0.875em', //14px
    padding: '16px 10px 16px 10px'
}));

export const StyledTableCellHeader = styled (TableCell)(({ theme }) => ({
  color: '#686868',
  fontWeight: 600,
  fontSize: '0.875em', //14px
  padding: '10px'
}));

export const StyledTableCellBody = styled (TableCell)(({ theme }) => ({
  color: 'theme.palette.neutral.letter_Black',
  fontWeight: 500,
  fontSize: '0.875em', //14px
  padding: '10px'
}));

export const TableCellSummary = styled (TableCell)(({ theme }) => ({
  color: 'theme.palette.neutral.letter_Black',
  fontWeight: 600,
  fontSize: '0.875em', //14px
  borderBottom: 'none',
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.Blue_Bg_Lite,
    borderRadius: '4px'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export const TableCellNoPadding = styled (TableCell)(({ theme }) => ({
  color: 'theme.palette.neutral.letter_Black',
  fontWeight: 500,
  // fontSize: '0.875em', //14px
  borderBottom: 'none',
  padding: '5px 10px 0 10px'
}));

export const TableCellInnerNoPadding = styled (TableCell)(({ theme }) => ({
  color: 'theme.palette.neutral.letter_Black',
  fontWeight: 500,
  // fontSize: '0.875em', //14px
  borderBottom: 'none',
  padding: '5px 15px 0 10px'
}));



