import moment from "moment";

export function getEventDates(value) {
  var eventFrom;
  var eventTo;

  if (value === undefined) {
    eventFrom = moment().subtract(7, "days").format("YYYY-MM-DD");
    eventTo = moment().format("YYYY-MM-DD");
  } else {
    eventFrom = moment(value[0]).format("YYYY-MM-DD");
    eventTo = moment(value[1]).format("YYYY-MM-DD");
  }

  return [eventFrom, eventTo];
}

export function formatCurrency(amount, currencyText) {
  const data = Math.round(amount * 100) / 100;
  const currencyCode = localStorage.getItem("currencyCode");

  const currency = currencyText ? currencyCode : '$';

  if (data < 0) {
    const newData = data.toString().replace("-", "");
    return (`
      (${currency+Number(newData)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")})`
    );
  } else if (data > 0) {
    const newData = data.toString().replace("-", "");
    return (
      `${currency+Number(newData)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
    );
  } else if (amount === 0) {
    return `${currency}0.00`;
  } else {
    return null;
  }
}

export function removeNegative(props) {
  const data = Math.round(props * 100) / 100;

  if (data < 0) {
    const newData = data.toString().replace("-", "");
    return (Number(newData));
  } else {
    return (Number(data));
  }
}

export function stringDate(value) {
  
  var eventFrom = moment(value[0]).format('YYYY-MM-DD');
  var eventTo = moment(value[1]).format('YYYY-MM-DD');

  // console.log(eventFrom + ' - ' + eventTo)

  return [eventFrom, eventTo];
}

export function showLastSevenDigits(str) {
  if (!str) {
    return ""; // Return an empty string or any other default value you prefer
  }
  if (str.length < 7) {
      return str; // If the string is shorter than 7 characters, return it as is
  }
  const lastSevenDigits = str.slice(-7); // Get the last 7 digits
  return "..." + lastSevenDigits; // Prepend '...'
}
