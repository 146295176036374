import { Typography, Grid, Stack, Box, Avatar } from "@mui/material";
import React from "react";

const boxStyle = {
  background: "#FFFFFF",
  p: 3,
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04)",
  borderRadius: "2px",
};

const marketplaceBoxStyle = {
  background: "#FFFFFF",
  p: 2,
  borderRadius: "2px",
  border: "1px solid var(--stroke, #868B98)",
};

export default function Marketplace() {
  return (
    <div>
      <br></br>
      <Box sx={boxStyle}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h3" sx={{ textTransform: "uppercase" }}>
            Connected Marketplaces
          </Typography>
        </Stack>

        <br></br>
        <Grid container justifyContent="flex-start" columnSpacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <Box sx={marketplaceBoxStyle}>
                <Grid container spacing={5} alignItems="flex-start">
                  <Grid item xs={2}>
                    <Avatar alt="AMAZON" src="/static/images/avatar/1.jpg" />
                  </Grid>
                  <Grid container item xs={10} spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        variant="h4"
                        sx={{ textTransform: "uppercase" }}
                      >
                        Amazon
                      </Typography>
                    </Grid>
                    <Grid container item xs={12} >
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            <span style={{ fontWeight: 400 }}>Store Name:</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            <span style={{ fontWeight: 400 }}>Status:</span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            <span style={{ fontWeight: 700 }}>
                              Gyber Grills LLC
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            <span style={{ fontWeight: 700, color: "#00AD47" }}>
                              Connected
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
            </Box>
          </Grid>
        </Grid>
        <br></br>
      </Box>
      <br></br>
    </div>
  );
}
