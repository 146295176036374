import {
  Typography,
  Grid,
  Stack,
  MenuItem,
  Box,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import { StyledTextBoxv2 } from "../../components/ui/TextBox";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { GreenContainedButton } from "../../components/ui/Buttons";
import { PostBankInfomation } from "../../services/auth.services";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// import { geocodeByAddress } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const boxStyle = {
  background: "#FFFFFF",
  p: 3,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
  borderRadius: "10px",
};

export default function BankInfo({ updateStep }) {
  const [bankInfo, setBankInfo] = useState({
    accountName: {
      value: "",
      error: false,
      message: "Account Name is required",
    },
    accountNumber: {
      value: "",
      error: false,
      message: "Account Number is required",
    },
    accountHolderName: {
      value: "",
      error: false,
      message: "Account Placeholder Name is required",
    },
    swiftBicNo: {
      value: "",
      error: false,
      message: "SWIFT / BAN Number is required",
    },
    routingNo: {
      value: "",
      error: false,
      message: "Routing Number is required",
    },
    payAccountType: {
      value: "PINGPONG",
      error: false,
      message: "Account Type is required",
    },
    bankName: {
      value: "",
      error: false,
      message: "Bank Name is required",
    },
    bankBranchCode: {
      value: "",
      error: false,
      message: "Bank Branch Code is required",
    },
    bankAddress: {
      value: "",
      error: false,
      message: "Bank Address is required",
    },
    bankCity: {
      value: "",
      error: false,
      message: "Bank City is required",
    },
    bankState: {
      value: "",
      error: false,
      message: "Bank State is required",
    },
    zipCode: {
      value: "",
      error: false,
      message: "Zip Code is required",
    },
    country: {
      value: "",
      error: false,
      message: "Country is required",
    },
    businessEncryptedId: {
      value: localStorage.getItem("businessEncryptedId"),
      error: false,
      message: "businessEncryptedId is required",
    },
  });
  const [inputAddress, setInputAddress] = useState(null);
  const [snackBarAlert, setSnackBarAlert] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const addressAPIKey = null;
  //AIzaSyADsyiSRV3CsnVV71hbkuwZhXhVU4kgeMs

  // useEffect(() => {
  //   if (inputAddress) {
  //     geocodeByAddress(inputAddress.label).then((place) => {
  //       let updatedBankInfo = { ...bankInfo, bankAddress: { value: inputAddress.label} }; // Create a copy of userInfo
        
  //       for (var i = 0; i < place[0].address_components.length; i++) {
  //         var addressType = place[0].address_components[i].types[0];

  //         switch (addressType) {
  //           case 'locality':
  //             updatedBankInfo.bankCity.value = place[0].address_components[i].long_name;
  //             break;
  //           case 'administrative_area_level_1':
  //             updatedBankInfo.bankState.value = place[0].address_components[i].long_name;
  //             break;
  //           case 'country':
  //             updatedBankInfo.country.value = place[0].address_components[i].long_name;
  //             break;
  //           case 'postal_code':
  //             updatedBankInfo.zipCode.value = place[0].address_components[i].long_name;
  //             break;
  //           default:
  //               break;
  //         }
  //       }

  //       setBankInfo(updatedBankInfo); // Update state once after loop
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [inputAddress])

  const handleSubmit = (e) => {
    e.preventDefault();

    // let error = false;
    const updatedBankInfo = { ...bankInfo };
    const propertyValues = Object.fromEntries(
      Object.entries(updatedBankInfo).map(([key, value]) => [key, value.value])
    );

    for (const key in updatedBankInfo) {
      if (
        !updatedBankInfo[key].value ||
        updatedBankInfo[key].value.trim() === ""
      ) {
        updatedBankInfo[key].error = true;
        setBankInfo({
          ...bankInfo,
          [key]: { ...bankInfo.error, error: true },
        });
        // error = true;
      } else {
        updatedBankInfo[key].error = false;
        // error = false;
      }
    }

    // if (!error) {
      // console.log('123')
      try {
        PostBankInfomation(propertyValues).then((data) => {
          if (data.ok) {
            updateStep(6);
          } else if (data.status === 500) {
            setSnackBarAlert(true);
            setSnackBarMessage("Email already exists");
          } else if (data.status === 400) {
            setSnackBarAlert(true);
            setSnackBarMessage("Please fill all fields");
          } else {
            setSnackBarMessage("Somthing went wrong please try again");
          }
        });
      } catch (error) {
        setSnackBarAlert(true);
        setSnackBarMessage("Somthing went wrong please try again");
        throw error;
      }
    // }
  };
  return (
    <div>
      <br></br>
      <Box sx={boxStyle}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ color: "#093BA7" }}
          alignItems="center"
        >
          <AccountBalanceIcon />
          <Typography variant="h5">Bank Information</Typography>
        </Stack>

        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Bank Name
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="ABC commercial banking PLC"
                value={bankInfo.bankName.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    bankName: {
                      ...bankInfo.bankName,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {bankInfo.bankName.error ? bankInfo.bankName.message : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Bank Branch Number
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="80045"
                value={bankInfo.bankBranchCode.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    bankBranchCode: {
                      ...bankInfo.bankBranchCode,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {bankInfo.bankBranchCode.error
                  ? bankInfo.bankBranchCode.message
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Bank Address
              </Typography>
              {addressAPIKey ? (
               <GooglePlacesAutocomplete
               apiKey="AIzaSyADsyiSRV3CsnVV71hbkuwZhXhVU4kgeMs"
               selectProps={{
                 inputAddress,
                 onChange: setInputAddress,
                 styles: {
                   control: (provided) => ({
                     ...provided,
                     border: "none",
                     borderRadius: "4px",
                     height: "2.5em",
                     background: '#F9F9FB',
                     color: "#28282A",
                   }),
                 },
               }}
             />
              ) : (
                <StyledTextBoxv2
                  fullWidth
                  value={bankInfo.bankAddress.value}
                  placeholder="Enter Address"
                  onChange={(event) =>
                    setBankInfo({
                      ...bankInfo,
                      bankAddress: {
                        ...bankInfo.bankAddress,
                        value: event.target.value,
                      },
                    })
                  }
                />
              )}
              <Typography variant="body3" color="red">
                {bankInfo.bankAddress.error ? bankInfo.bankAddress.message : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                City
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="Phoenix"
                value={bankInfo.bankCity.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    bankCity: {
                      ...bankInfo.bankCity,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {bankInfo.bankCity.error ? bankInfo.bankCity.message : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                State
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="Arizona"
                value={bankInfo.bankState.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    bankState: {
                      ...bankInfo.bankState,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {bankInfo.bankState.error ? bankInfo.bankState.message : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Zip code
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="10008"
                value={bankInfo.zipCode.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    zipCode: {
                      ...bankInfo.zipCode,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {bankInfo.zipCode.error ? bankInfo.zipCode.message : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Country
              </Typography>
              <StyledTextBoxv2 fullWidth placeholder="United State" value={bankInfo.country.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    country: {
                      ...bankInfo.country,
                      value: event.target.value,
                    },
                  })
                } />
                 <Typography variant="body3" color="red">
                {bankInfo.country.error ? bankInfo.country.message : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                SWIFT / BAN Number
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="SWIFT 00116789"
                value={bankInfo.swiftBicNo.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    swiftBicNo: {
                      ...bankInfo.swiftBicNo,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {bankInfo.swiftBicNo.error ? bankInfo.swiftBicNo.message : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <br></br>
      <Box sx={boxStyle}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ color: "#093BA7" }}
          alignItems="center"
        >
          <FolderSharedIcon />
          <Typography variant="h5">Account Information</Typography>
        </Stack>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Account Name
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="Saving Account"
                value={bankInfo.accountName.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    accountName: {
                      ...bankInfo.accountName,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {bankInfo.accountName.error ? bankInfo.accountName.message : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Account Number
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="example@gmail.com"
                value={bankInfo.accountNumber.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    accountNumber: {
                      ...bankInfo.accountNumber,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {bankInfo.accountNumber.error
                  ? bankInfo.accountNumber.message
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Account Holder Name
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="Mafaz Jaufer"
                value={bankInfo.accountHolderName.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    accountHolderName: {
                      ...bankInfo.accountHolderName,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {bankInfo.accountHolderName.error
                  ? bankInfo.accountHolderName.message
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Routing Number
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="R 0045"
                value={bankInfo.routingNo.value}
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    routingNo: {
                      ...bankInfo.routingNo,
                      value: event.target.value,
                    },
                  })
                }
              />
              <Typography variant="body3" color="red">
                {bankInfo.routingNo.error ? bankInfo.routingNo.message : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Account Type
              </Typography>
              <StyledTextBoxv2
                fullWidth
                value={bankInfo.payAccountType.value}
                select
                onChange={(event) =>
                  setBankInfo({
                    ...bankInfo,
                    payAccountType: {
                      ...bankInfo.payAccountType,
                      value: event.target.value,
                    },
                  })
                }
              >
                <MenuItem value="PINGPONG">PingPong</MenuItem>
                <MenuItem value="SAVINGS">Savings</MenuItem>
                <MenuItem value="CHECKING">Checking</MenuItem>
              </StyledTextBoxv2>
              <Typography variant="body3" color="red">
                {bankInfo.payAccountType.error
                  ? bankInfo.payAccountType.message
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <br></br>
      <Stack justifyContent="flex-end" alignItems="flex-end">
        <GreenContainedButton
          variant="contained"
          onClick={(e) => handleSubmit(e)}
          endIcon={<ArrowForwardIosIcon />}
        >
          Next
        </GreenContainedButton>
      </Stack>
      <br></br>
      <Snackbar
        open={snackBarAlert}
        onClose={() => setSnackBarAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ top: "0 !important", left: "0 !important" }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setSnackBarAlert(false)}
          sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            <CancelOutlinedIcon fontSize="small" />
            <Typography variant="body2">{snackBarMessage}</Typography>
          </Stack>
        </Box>
      </Snackbar>
      <br></br>
    </div>
  );
}
