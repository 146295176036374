// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tel-input .form-control {
    box-shadow: "none";
    /* border: 1.5px solid #d2302f !important; */
    border-radius: 4px !important;
    height: 2.5em !important;
    width: 100% !important;
}

.react-tel-input .country-list {
    text-align: left !important;
}

.react-tel-input .select-flag {
    background: #ffffff !important;
    border-radius: 8px 0px 0px 8px !important;
    border-right: 0px !important;
}

.react-tel-input .selected-flag {
    background: #ffffff !important;
    border-radius: 8px 0px 0px 8px !important;
    border-right: 0px !important;
    width: 0 !important;
    /* border: 2px solid red !important; */

}

.react-tel-input .flag-dropdown {
    background: #ffffff !important;
    border-radius: 8px 0px 0px 8px !important;
    border: 0px !important;
    top: 5px !important;
    bottom: 5px !important;
    left: 5px !important;
    /* border-left: 1.5px solid #d2302f !important;
    border-top: 1.5px solid #d2302f !important;
    border-bottom: 1.5px solid #d2302f !important; */



}

.react-tel-input .flag-dropdown.open .selected-flag {
    background: #F6F6F6 !important;
    border-radius: 8px 0px 0px 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/view/configure/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,4CAA4C;IAC5C,6BAA6B;IAC7B,wBAAwB;IACxB,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,8BAA8B;IAC9B,yCAAyC;IACzC,4BAA4B;AAChC;;AAEA;IACI,8BAA8B;IAC9B,yCAAyC;IACzC,4BAA4B;IAC5B,mBAAmB;IACnB,sCAAsC;;AAE1C;;AAEA;IACI,8BAA8B;IAC9B,yCAAyC;IACzC,sBAAsB;IACtB,mBAAmB;IACnB,sBAAsB;IACtB,oBAAoB;IACpB;;oDAEgD;;;;AAIpD;;AAEA;IACI,8BAA8B;IAC9B,yCAAyC;AAC7C","sourcesContent":[".react-tel-input .form-control {\n    box-shadow: \"none\";\n    /* border: 1.5px solid #d2302f !important; */\n    border-radius: 4px !important;\n    height: 2.5em !important;\n    width: 100% !important;\n}\n\n.react-tel-input .country-list {\n    text-align: left !important;\n}\n\n.react-tel-input .select-flag {\n    background: #ffffff !important;\n    border-radius: 8px 0px 0px 8px !important;\n    border-right: 0px !important;\n}\n\n.react-tel-input .selected-flag {\n    background: #ffffff !important;\n    border-radius: 8px 0px 0px 8px !important;\n    border-right: 0px !important;\n    width: 0 !important;\n    /* border: 2px solid red !important; */\n\n}\n\n.react-tel-input .flag-dropdown {\n    background: #ffffff !important;\n    border-radius: 8px 0px 0px 8px !important;\n    border: 0px !important;\n    top: 5px !important;\n    bottom: 5px !important;\n    left: 5px !important;\n    /* border-left: 1.5px solid #d2302f !important;\n    border-top: 1.5px solid #d2302f !important;\n    border-bottom: 1.5px solid #d2302f !important; */\n\n\n\n}\n\n.react-tel-input .flag-dropdown.open .selected-flag {\n    background: #F6F6F6 !important;\n    border-radius: 8px 0px 0px 8px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
