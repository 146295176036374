import { Typography, Grid, Stack, MenuItem, Box } from "@mui/material";
import React from "react";
import { ContainedButton } from "../../components/ui/Buttons";
import { StyledTextBox } from "../../components/ui/TextBox";

const boxStyle = {
  background: "#FFFFFF",
  p: 3,
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04)",
  borderRadius: "2px",
};

export default function Business() {
  return (
    <div>
      <br></br>
      <Box sx={boxStyle}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h3" sx={{ textTransform: "uppercase" }}>
          basic information
          </Typography>
        </Stack>

        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
              Business Name
              </Typography>
              <StyledTextBox fullWidth placeholder="Vepay Inc" />
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
              Segment
              </Typography>
              <StyledTextBox fullWidth placeholder="E-Commerce" />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h3" sx={{ textTransform: "uppercase" }}>
          business contact information
          </Typography>
        </Stack>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Contact Number
              </Typography>
              <StyledTextBox fullWidth placeholder="+1 234 567 890" />
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Email
              </Typography>
              <StyledTextBox fullWidth placeholder="example@gmail.com" />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Address
              </Typography>
              <StyledTextBox fullWidth placeholder="No : Group 123 / A" />
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                City
              </Typography>
              <StyledTextBox fullWidth placeholder="Phoenix" />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                State
              </Typography>
              <StyledTextBox fullWidth placeholder="Arizona" />
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Zip code
              </Typography>
              <StyledTextBox fullWidth placeholder="10008" />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Country
              </Typography>
              <StyledTextBox fullWidth value="United States" select>
                <MenuItem value="United States">United States</MenuItem>
              </StyledTextBox>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <ContainedButton variant="contained">Update Now</ContainedButton>
      </Box>
      <br></br>
      
    </div>
  );
}
