import React from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Grid,
  MenuItem,
  Dialog,
  Snackbar,
} from "@mui/material";
import { ContainedButton } from "../../components/ui/Buttons";
import { NoBorderTextArea, NoBorderTextBox } from "../../components/ui/TextBox";
import { PostHelp } from "../../services/support.services";
import Support from "../../assets/support.svg";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useOutletContext } from "react-router-dom";

export default function CreateTicket() {
  const [ticketSubject, setTicketSubject] = useState("");
  const [issueType, setIssueType] = useState("DASHBOARD_ISSUE");
  const [ticketDescription, setTicketDescription] = useState("");

  const [errorSubject, setErrorCheckSubject] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);

  const [subjectMessage, setSubjectMessage] = useState("");
  const [descriptionMessage, setDescriptionMessage] = useState("");

  const [snackBarAlert, setSnackBarAlert] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const [userData] = useOutletContext();

  const [open, setOpen] = useState(false);
  const [referenceNo, setReferenceNo] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;

    const businessEncryptedId = userData.businessEncryptedId;
    const attachmentUrlPath = "no-image";

    if (ticketSubject === "" || ticketSubject === null) {
      error = true;
      setErrorCheckSubject(true);
      setSubjectMessage("Title is required");
    } else {
      setSubjectMessage("");
      setErrorCheckSubject(false);
    }

    if (ticketDescription === "" || ticketDescription === null) {
      error = true;
      setErrorDescription(true);
      setDescriptionMessage("Description is required");
    } else {
      setErrorDescription(false);
      setDescriptionMessage("");
    }

    const value = {
      ticketSubject,
      issueType,
      ticketDescription,
      businessEncryptedId,
      attachmentUrlPath,
    };

    if (!error) {
      try {
        PostHelp(value).then((data) => {
          if (data.response.ok) {
            setReferenceNo(data.result.referenceNo);
            setOpen(true);
            setTicketSubject("");
            setIssueType("DASHBOARD_ISSUE");
            setTicketDescription("");
          } else if (data.response.status === 400) {
            setSnackBarAlert(true);
            setSnackBarMessage("Please fill all fields");
          } else {
            setSnackBarAlert(true);
            setSnackBarMessage("Somthing went wrong please try again");
          }
        });
      } catch (error) {
        setSnackBarAlert(true);
        setSnackBarMessage("Somthing went wrong please try again");
        throw error;
      }
    }
  };

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 0px 4px rgba(3, 40, 120, 0.18)",
          borderRadius: "2px",
        }}
      >
        <Grid container spacing={2} sx={{ backgroundColor: "white" }}>
          <Grid item xs={12} sm={8} md={8}>
            <Stack
              spacing={4}
              justifyContent="flex-start"
              sx={{ marginLeft: "5%", marginTop: "3%" }}
            >
              <Stack spacing={1}>
                <Typography
                  textAlign="left"
                  variant="h4"
                  sx={{ textTransform: "uppercase" }}
                >
                  Tell us about your issue
                </Typography>
                <Typography textAlign="left" variant="subtitle2">
                  Please feel free to share your issue with us and we will
                  contact you as soon as possible to resolve it.
                </Typography>
              </Stack>
              <Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ "& > :not(style)": { width: "100%" } }}
                >
                  <Stack spacing={1}>
                    <Typography variant="body2" textAlign="left">
                      <b>Issue Summary</b>
                    </Typography>
                    <NoBorderTextBox
                      fullWidth
                      sx={{ height: 45 }}
                      error={errorSubject ? true : false}
                      inputProps={{ maxLength: 100 }}
                      placeholder="Ticket title here"
                      value={ticketSubject}
                      onChange={(e) => setTicketSubject(e.target.value)}
                    />
                  </Stack>
                </Stack>
                <Typography variant="body3" color="red">
                  {subjectMessage}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                sx={{ "& > :not(style)": { width: "100%" } }}
              >
                <Stack spacing={1}>
                  <Typography variant="body2" textAlign="left">
                    <b>Issue Category</b>
                  </Typography>
                  <NoBorderTextBox
                    fullWidth
                    select
                    id="outlined-basic"
                    variant="outlined"
                    value={issueType}
                    onChange={(e) => setIssueType(e.target.value)}
                    size="small"
                    sx={{
                      textAlign: "left",
                      height: 45,
                    }}
                  >
                    <MenuItem value="DASHBOARD_ISSUE">Dashboard</MenuItem>
                    <MenuItem value="TRANSFER_FUND_ISSUE">
                      Transfer fund
                    </MenuItem>
                    <MenuItem value="DAILY_SUMMARY_ISSUE">
                      Daily Summary
                    </MenuItem>
                    <MenuItem value="REPORTS_ISSUE">Reports</MenuItem>
                    <MenuItem value="SETTINGS_ISSUE">Settings</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
                  </NoBorderTextBox>
                </Stack>
              </Stack>

              <Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ "& > :not(style)": { width: "100%" } }}
                >
                  <Stack spacing={1}>
                    <Typography variant="body2" textAlign="left">
                      <b>Describe your issue</b>
                    </Typography>
                    <NoBorderTextArea
                      fullWidth
                      multiline
                      error={errorDescription ? true : false}
                      rows={6}
                      inputProps={{ maxLength: 1023 }}
                      value={ticketDescription}
                      onChange={(e) => setTicketDescription(e.target.value)}
                      placeholder="Type your issue here"
                    />
                  </Stack>
                </Stack>
                <br></br>
                <Typography variant="body3" color="red">
                  {descriptionMessage}
                </Typography>
              </Stack>

              <br></br>
              <Stack direction="row" spacing={2} alignItems="center">
                <ContainedButton
                  variant="contained"
                  size="large"
                  onClick={(e) => handleSubmit(e)}
                >
                  Send Ticket
                </ContainedButton>
              </Stack>
              <br></br>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 6 }} textAlign="center">
          <Typography variant="h1" textAlign="center">
            Please hold on!
          </Typography>
          <br></br>
          <img src={Support} alt="support" />
          <br></br>
          <Typography variant="h5_light" textAlign="center">
            Thank you for contacting us, please hold on. One of our agents will
            contact you soon regarding your issue{" "}
            <b>(Ref:&nbsp;{referenceNo})</b>. Sorry for inconvenience occurred.
          </Typography>
          <br></br>
          <br></br>
          <Stack alignItems="center">
            <ContainedButton
              variant="contained"
              size="large"
              fullWidth
              onClick={handleClose}
            >
              Done
            </ContainedButton>
          </Stack>
        </Box>
      </Dialog>

      <Snackbar
        open={snackBarAlert}
        onClose={() => setSnackBarAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ top: "0 !important", left: "0 !important", zIndex: 10000 }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setSnackBarAlert(false)}
          sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            <CancelOutlinedIcon fontSize="small" />
            <Typography variant="body2">{snackBarMessage}</Typography>
          </Stack>
        </Box>
      </Snackbar>
    </div>
  );
}
