import { Typography, Grid, Stack, MenuItem, Box } from "@mui/material";
import React from "react";
import { ContainedButton } from "../../components/ui/Buttons";
import { StyledTextBox } from "../../components/ui/TextBox";

const boxStyle = {
  background: "#FFFFFF",
  p: 3,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
  borderRadius: "10px",
};

export default function Bank() {
  return (
    <div>
      <br></br>
      <Box sx={boxStyle}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Typography variant="h3" sx={{ textTransform: "uppercase" }}>Bank Information</Typography>
        </Stack>

        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Bank Name
              </Typography>
              <StyledTextBox
                fullWidth
                placeholder="ABC commercial banking PLC"
              />
            </Grid>
          </Grid>
          <Grid xs={1} />
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Bank Branch Number
              </Typography>
              <StyledTextBox fullWidth placeholder="80045" />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Bank Address
              </Typography>
              <StyledTextBox fullWidth placeholder="No : Group 123 / A" />
            </Grid>
          </Grid>
          <Grid xs={1} />
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                City
              </Typography>
              <StyledTextBox fullWidth placeholder="Phoenix" />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                State
              </Typography>
              <StyledTextBox fullWidth placeholder="Arizona" />
            </Grid>
          </Grid>
          <Grid xs={1} />
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Zip code
              </Typography>
              <StyledTextBox fullWidth placeholder="10008" />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Country
              </Typography>
              <StyledTextBox fullWidth value="United States" select>
                <MenuItem value="United States">United States</MenuItem>
              </StyledTextBox>
            </Grid>
          </Grid>
          <Grid xs={1} />
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                SWIFT / BAN Number
              </Typography>
              <StyledTextBox fullWidth placeholder="SWIFT 00116789" />
            </Grid>
          </Grid>
        </Grid>
      <br></br>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Typography variant="h3" sx={{ textTransform: "uppercase" }}>Account details</Typography>
        </Stack>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Account Name
              </Typography>
              <StyledTextBox fullWidth placeholder="Saving Account" />
            </Grid>
          </Grid>
          <Grid xs={1} />
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Account Number
              </Typography>
              <StyledTextBox fullWidth placeholder="example@gmail.com" />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Account Holder Name
              </Typography>
              <StyledTextBox fullWidth placeholder="Mafaz Jaufer" />
            </Grid>
          </Grid>
          <Grid xs={1} />
          <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Account Type
              </Typography>
              <StyledTextBox fullWidth placeholder="Saving" />
            </Grid>
        </Grid>
       
        </Grid>
        <br></br>
        <Grid xs={5} container direction="row" alignItems="center">
            <Grid xs={12}>
              <Typography variant="h5" color="neutral.Gray_Dark">
                Routing Number
              </Typography>
              <StyledTextBox fullWidth placeholder="R 0045" />
            </Grid>
          </Grid>

          <br></br>
      <ContainedButton variant="contained">Update Now</ContainedButton>
      </Box>
      
      <br></br>
    </div>
  );
}
